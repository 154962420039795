import { useBaseActions } from '../base.actions';
import { enderecosAtom } from './EnderecoState';

function useEnderecosActions() {
  const baseActions = useBaseActions('enderecos', enderecosAtom, false);
 
  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useEnderecosActions };
