import { createTheme } from '@mui/material';
import { ptBR } from '@mui/x-date-pickers/locales';
import { ptBR as ptBrGrid } from '@mui/x-data-grid';

export const theme = createTheme(
  {
    typography: {
      fontFamily: 'Montserrat, Roboto, Oxygen',
      h1: {
        fontSize: '40px',
        lineHeight: '45px',
        fontWeight: '700',
        color: '#585858',
      },
      h2: {
        color: '#585858',
        fontSize: '35px',
        lineHeight: '40px',
        fontStyle: 'normal',
        fontWeight: 600,
      },
      h3: {
        color: '#585858',
        fontSize: '30px',
        lineHeight: '33px',
        fontStyle: 'normal',
        fontWeight: 600,
      },
      h4: {
        color: '#585858',
        fontSize: '27px',
        fontStyle: 'normal',
        lineHeight: '30px',
      },
      h5: {
        color: '#585858',
        fontSize: '25px',
        fontWeight: 600,
        lineHeight: '27px',
      },
      subtitle2: {
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '20px',
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          // root: ({ ownerState }) => ({
          //   ...(ownerState.variant === 'contained' &&
          //     ownerState.color === 'primary' && {
          //       background: 'linear-gradient(60deg, red 0%, blue 50%, grey 100%)'
          //     }),
          //     borderRadius: '2.5rem',
          // }),
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: '10px !important',
          },
        },
      },
    },
  },
  ptBR,
  ptBrGrid,
);
