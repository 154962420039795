import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Logo from './../../assets/Logo-Stato-Intoo_1.png';

import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { styled } from 'styled-components';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { history } from '../../helpers/history';
import { authAtom } from '../../states/usuarios/AuthState';
import { useUsuarioActions } from '../../states/usuarios/usuario.actions';
import { FormControlStato, GridContainer } from '../../commons/styleds/styleds';
import { GridItem } from '../../components/Menu';
import banner from '../../assets/1.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { AppBar, Box, Checkbox, Divider, Tab, Tabs } from '@mui/material';
import { idID } from '@mui/material/locale';
import SearchIcon from '@mui/icons-material/Search';
import { useAcoesActions } from '../../states/acao/acao.actions';
import { IAcao } from '../../commons/types/crm';
import { IBaseNome, IIdNome } from '../../commons/types/base';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

const Acoes: React.FC = () => {
    const auth = useRecoilValue(authAtom);
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [listTab, setListTab] = useState<any[]>([]);

    const acaoActions = useAcoesActions();
    const [acoes, setAcoes] = useState<IAcao[]>([]);
    const [configAcoes, setConfigAcoes] = useState<IIdNome[]>([]);
    
    const [oportunidadesIds, setOportunidadesIds] = useState<number[]>([]);
    const [reunioesIds, setReunioesIds] = useState<number[]>([]);
    const [empresasIds, setEmpresasIds] = useState<number[]>([]);
    const [indicacoesIds, setIndicacoesIds] = useState<number[]>([]);
    const [eventosIds, setEventosIds] = useState<number[]>([]);
    const [outrasAcoesIds, setOutrasAcoesIds] = useState<number[]>([]);
    const sobra = useState<number[]>([]);


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);

        switch (newValue) {
            case 0:
                setListTab(acoes.filter((f) => oportunidadesIds.indexOf(f.idTipo) >= 0 ));
                break;
            case 1:
                setListTab(acoes.filter((f) => reunioesIds.indexOf(f.idTipo) >= 0 ));
                break;
            case 2:
                setListTab(acoes.filter((f) => empresasIds.indexOf(f.idTipo) >= 0 ));
                break;
            case 3:
                setListTab(acoes.filter((f) => indicacoesIds.indexOf(f.idTipo) >= 0 ));
                break;
            case 4:
                setListTab(acoes.filter((f) => eventosIds.indexOf(f.idTipo) >= 0 ));
                break;
            case 5:
                setListTab(acoes.filter((f) => outrasAcoesIds.indexOf(f.idTipo) >= 0 ));
                break;

            default:
                break;
        }

    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    const onSubmit = (e: any) => {
        e.preventDefault();



    };

    useEffect(() => {

        acaoActions.list(auth?.comanda.id)
            .then((data: IAcao[]) => {
                setAcoes(data);
            });

        acaoActions.listConfig()
            .then((data: IIdNome[]) => {
                setConfigAcoes(data);
            });


    }, [auth]);

    useEffect(() => {
        setListTab(acoes.filter((f) => f.idTipo == 2));

        setOportunidadesIds(configAcoes.filter((f) => f.nome == "Candidatura no Banco de Oportunidades").map((m) => m.id));

        setReunioesIds(configAcoes.filter((f) => 
            (f.nome == "Criação de Lista de HeadHunters") ||
            (f.nome == "Criação de Lista de Empresas")
        ).map((m) => m.id));

        setIndicacoesIds(configAcoes.filter((f) => 
            (f.nome == "Indicação p/ Vaga Anunciada") ||
            (f.nome == "Indicação p/ HeadHunter") ||
            (f.nome == "Indicação p/ Mercado-alvo")
        ).map((m) => m.id));

        setEventosIds(configAcoes.filter((f) => f.nome == "Participação em Evento").map((m) => m.id));

        var ids = configAcoes.filter((f) => 
            (f.nome == "Candidatura no Banco de Oportunidades") ||
            (f.nome == "Criação de Lista de HeadHunters") ||
            (f.nome == "Criação de Lista de Empresas") ||
            (f.nome == "Indicação p/ Vaga Anunciada") ||
            (f.nome == "Indicação p/ HeadHunter") ||
            (f.nome == "Indicação p/ Mercado-alvo") ||
            (f.nome == "Indicação p/ Vaga Anunciada") ||
            (f.nome == "Participação em Evento")
        ).map((m) => m.id);
        
        setOutrasAcoesIds(configAcoes.filter((f) => !(ids.indexOf(f.id) >= 0) ).map((m) => m.id));

    }, [acoes]);

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index: number) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    }

    return (
        <GridContainer container direction="row" spacing={0} sx={{ display: "flex", padding: "10px 15px 10px 10px", margin: "0px 30px !important", backgroundColor: "white" }}>
            <Grid container direction="row" spacing={0} sx={{ paddingTop: "25px", display: "flex", flexWrap: "wrap", alignContent: "flex-start" }} xs={12} md={12}>
                <Grid xs={12} md={12} sx={{ paddingBottom: "30px" }}>
                    <Typography sx={{ padding: "10px" }} variant="h5" component="h2">
                        Ações
                    </Typography>
                    <Divider orientation="horizontal" />
                </Grid>

                <Grid container xs={12} md={12} sx={{ paddingBottom: "10px" }}>
                    <Typography sx={{ padding: "10px" }} variant="caption">
                        Planejar e gerenciar suas atividades são ações fundamentais para aumentar a produtividade no seu processo de transição de carreira. As informações abaixo são relacionadas ao seu processo e tem como objetivo registrar indicações feitas pelo seu Consultor, suas candidaturas no banco de oportunidades e demais sites de vagas, bem como acompanhar os processos seletivos nos quais você está participando.
                    </Typography>
                </Grid>

                <Grid container xs={12} md={12}>
                    <Grid container xs={12} md={12} sx={{ padding: "10px" }}>
                        <Grid container sx={{ padding: "5px" }} xs={12} md={12}>
                            <Box sx={{ bgcolor: 'background.paper', width: "100%" }}>
                                <AppBar position="static">
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        indicatorColor="secondary"
                                        textColor="inherit"
                                        variant="fullWidth"
                                        aria-label="full width tabs example"
                                    >
                                        <Tab label="Oportunidades" {...a11yProps(0)} />
                                        <Tab label="Reuniões" {...a11yProps(1)} />
                                        <Tab label="Lista de Empresa e HeadHunters" {...a11yProps(2)} />
                                        <Tab label="Indicações" {...a11yProps(3)} />
                                        <Tab label="Eventos" {...a11yProps(4)} />
                                        <Tab label="Outras Ações" {...a11yProps(5)} />
                                    </Tabs>
                                </AppBar>

                                <Grid sx={{ padding: "10px 0px" }} container xs={12} md={12}>
                                    <Grid container xs={6} md={6}>
                                        <ButtonAction sx={{ marginRight: "10px" }}>Copy</ButtonAction>
                                        <ButtonAction sx={{ marginRight: "10px" }}>Excel</ButtonAction>
                                        <ButtonAction sx={{ marginRight: "10px" }}>PDF</ButtonAction>
                                    </Grid>
                                    <Grid container xs={6} md={6} sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-end" }}>
                                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                            <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                            <TextField id="input-with-sx" label="Filtrar" variant="standard" />
                                        </Box>
                                    </Grid>
                                </Grid>

                                <TabPanel value={0} index={0} dir={theme.direction}>
                                    <TableContainer component={Paper}>
                                        <Table size="small" aria-label="a dense table">
                                            <TableHead sx={{ minWidth: 650, backgroundColor: "#0e95ba85" }}>
                                                <TableRow>
                                                    <TableCell>Ação</TableCell>
                                                    <TableCell>Observação</TableCell>
                                                    <TableCell>Data</TableCell>
                                                    <TableCell>Anexo</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody sx={{ backgroundColor: "#0392ca24" }}>
                                                {listTab.map((row) => (
                                                    <TableRow
                                                        key={row.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {configAcoes.find((f) => f.id == row.idTipo)?.nome}
                                                        </TableCell>
                                                        <TableCell>{row.observacoes}</TableCell>
                                                        <TableCell>{row.dtPrevista && new Date(row.dtPrevista.toString()).toLocaleDateString()}</TableCell>
                                                        <TableCell>{row.anexo}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                                {/* <TabPanel value={value} index={1} dir={theme.direction}>
                                    Reuniões
                                </TabPanel>
                                <TabPanel value={value} index={2} dir={theme.direction}>
                                    Lista de Empresa e HeadHunters
                                </TabPanel>
                                <TabPanel value={value} index={3} dir={theme.direction}>
                                    Indicações
                                </TabPanel>
                                <TabPanel value={value} index={4} dir={theme.direction}>
                                    Eventos
                                </TabPanel>
                                <TabPanel value={value} index={5} dir={theme.direction}>
                                    Outras Ações
                                </TabPanel> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </GridContainer>
    );
};

const ButtonAction = styled(Button)`
  background-color: #0091cf !important;
  color: white !important;
  border-radius: 10px !important;
`;


export default Acoes;












