
import { useBaseOneActions } from "../base.actionsOne";
import { escopoResponsabilidadeAtom } from "./EscopoResponsabilidadeState";

function useEscopoResponsabilidadeActions() {
  const baseActions = useBaseOneActions('escopoResponsabilidade', escopoResponsabilidadeAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  }
}

export { useEscopoResponsabilidadeActions };

