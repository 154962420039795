import { useBaseActions } from '../base.actions';
import { graduacoesAtom } from './GraduacaoState';

function useGraduacoesActions() {
  const baseActions = useBaseActions('graduacoes', graduacoesAtom, false);
 
  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useGraduacoesActions };
