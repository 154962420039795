import { Route, Switch } from "react-router-dom";
import Pagina404 from './pages/Pagina404';
import MenuBar from './components/Menu';
import Login from './pages/Login';
import MinhaTransicao from "./pages/MinhaTransicao";
import Acoes from "./pages/Acoes";
import Programa from "./pages/Programa";
import DadosPessoais from "./pages/DadosPessoais";
import PPC from "./pages/PPC";
import PaginasDinamicas from "./pages/PaginasDinamicas";
// import Dashboard from './pages/Dashboard';
// import Reunioes from './pages/Reunioes';
// import Assessorados from './pages/Assessorados/Index';
// import Configuracoes from './pages/Configuracoes';
// import AssessoradoDetalhes from './pages/Assessorados/Detalhes';

function getRoutes() {
  let modulos: IModulo[] = JSON.parse(localStorage.getItem('menusDinamicos') ?? '[]');
  modulos = modulos.filter(area => area.nome !== 'Minha Transição')
  let modulosDinamicos: string[] = modulos.map(map => map.link);

  let ad = modulos.filter(f => f.filhos != null && f.filhos != undefined);

  for (let index = 0; index < ad.length; index++) {
    ad[index].filhos?.map(map => modulosDinamicos.push(ad[index].link + "/" + map.link));

    ad[index].filhos?.forEach(filho => {
      filho.filhos?.map(map => modulosDinamicos.push(ad[index].link + "/" + filho.link + "/" + map.link));

      filho.filhos?.forEach(filho2 => {
        filho2.filhos?.map(map => modulosDinamicos.push(ad[index].link + "/" + filho.link + "/" + filho2.link + "/" + map.link));
      })
    })


  }

  return modulosDinamicos
}

interface IModulo {
  privilegio: string;
  nome: string;
  icon: string;
  link: string;
  filhos?: IModulo[];
}

function Routes() {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <MenuBar>
        <Route exact path="/" component={MinhaTransicao} />
        <Route exact path="/minha-transicao" component={MinhaTransicao} />
        <Route exact path="/minha-transicao/acoes" component={Acoes} />
        <Route exact path="/minha-transicao/programa" component={Programa} />
        <Route exact path="/minha-transicao/dados-pessoais" component={DadosPessoais} />
        <Route exact path="/minha-transicao/programa/ppc" component={PPC} />

        {getRoutes().map(menu => <Route exact path={menu} component={PaginasDinamicas} />)}

        {/* <Route exact path="/reunioes" component={Reunioes} />
        
        <Route exact path="/assessorados" component={Assessorados} />
        <Route exact path="/assessorados/:id" component={AssessoradoDetalhes} />

        <Route exact path="/configuracoes" component={Configuracoes} />  */}
      </MenuBar>
      <Route exact component={Pagina404} />
    </Switch>
  );
}

export default Routes;
