import { analisePerfisAtom } from './AnalisePerfilState';
import { RecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useBaseActions } from '../base.actions';
import { useAxiosWrapper } from '../../services/axiosWrapper';
import { authAtom } from '../usuarios/AuthState';
import { history } from '../../helpers/history';

function useAnalisePerfisActions() {
  const baseActions = useBaseActions('analisePerfis', analisePerfisAtom, false);
  const axiosWrapper = useAxiosWrapper();
  const authState = useRecoilValue(authAtom);
  const setAuth = useSetRecoilState(authAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
  
}

export { useAnalisePerfisActions };
