import { FormControl, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';

interface Props {
  novoUsuario: boolean;
}

const PPCStepper2: React.FC<Props> = (props: Props) => {
  const { novoUsuario } = props;
  const methods = useFormContext();

  return (
    <>
      <Grid container xs={12} md={12} sm={12} sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
      }}>
        <Grid xs={12} md={12} sm={12}>
          <Typography sx={{ padding: "10px 0px" }} variant="h4" component="h2">
            Referências
          </Typography>
        </Grid>
        <Grid container xs={12} md={12} sx={{ paddingBottom: "10px", display: "flex" }}>
          <Typography sx={{ padding: "10px" }} variant="caption">
            Este mapa de referências lhe dará agilidade para apresentar informações de maneira completa e estruturada. Faça uma lista de pessoas que possam ser indicadas como suas referências profissionais (pelo menos 2 superiores, 2 pares e 2 subordinados).
          </Typography>
        </Grid>

        <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "15px", width: "100%", marginTop: "10px" }}>
          <Controller
            control={methods.control}
            name='referencia'
            render={({ field: { ref, ...field } }) => (
              <TextField
                sx={{ width: "100%" }}
                id="standard-multiline-static"
                {...field}
                inputRef={ref}
                value={field.value}
                label=""
                variant="outlined"
                multiline
                rows={8}
              />
            )}
          />
        </Grid>

      </Grid>
    </>
  );
};

export default PPCStepper2;
