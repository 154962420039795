
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
    Button, CircularProgress, Dialog, DialogContent, Divider, FormControl, TableCell, TextField,
    Box,
    Checkbox,
    FormControlLabel,
    Alert,
    Drawer,
    Icon,
    Autocomplete,
    DialogTitle,
    DialogContentText,
    DialogActions,
    Accordion,
    InputLabel,
    MenuItem,
    createFilterOptions,
    LinearProgress,
} from '@mui/material';
import { useEmpresasActions } from '../../../states/empresas/empresa.actions';
import { IContatoHistoricoEmpresa, IEmpresa, INivelPosicao } from '../../../commons/types/crm';
import { ButtonStato, IconDrawer, SelectStato, TextFieldStato } from '../../../commons/styleds/styleds';
import { GridItemCadastro } from '../../../commons/styleds/grids';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../../states/usuarios/AuthState';
import moment from 'moment';
import dayjs from 'dayjs';
import { AccordionDetails, AccordionSummary } from '../accordionAndTabPanel';
import { IAreaPosicao, IContatoHistoricoEmpresaPosicao, IEmpresaType, IEscopoResonsabilidade, IHistorico } from '../../../commons/styleds/historicoProdissional';
import { useConfigNivelFluenciasActions } from '../../../states/configNivelFluencia/configNivelFluencia.actions';
import { useContatoHistoricoEmpresaActions } from '../../../states/contatoHistoricoEmpresas/ContatoHistoricoEmpresaActions';
import { useAreaPosicaoActions } from '../../../states/areaPosicoes/areaPosicao.actions';
import { useContatoHistoricoEmpresaPosicoesActions } from '../../../states/ContatoHistoricoEmpresaPosicao/ContatoHistoricoEmpresaPosicoesActions';
import { useEscopoResponsabilidadeActions } from '../../../states/escopoResponsabilidade/escopoResponsabilidade.actions';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


interface Props {
    novoUsuario: boolean;
}

export const filter = createFilterOptions<IEmpresaType>();

const emptyPosicao: any = {
    id: 0,
    historicoEmpresaId: 0,
    cargo: '',
    realizacoes: '',
    responsabilidades: '',
    motivoSaida: '',
    superior: '',
    cargoAtual: false,
    numeroDeSubordinados: 0,
    areaId: 0,
    nivelId: 0,
    escopoId: 0,
    dataInicio: null,
    dataTermino: null
}

const HistoricoProfissionalComponent: React.FC = () => {
    const auth = useRecoilValue(authAtom);
    const [open, setOpen] = useState<boolean>(false);
    const [loadingForm, setLoadingForm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingGet, setLoadingGet] = useState<boolean>(true);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const contatoHistoricoEmpresaActions = useContatoHistoricoEmpresaActions();
    const contatoHistoricoEmpresaPosicoesActions = useContatoHistoricoEmpresaPosicoesActions();
    const empresaActions = useEmpresasActions();
    const areaActions = useAreaPosicaoActions();
    const nivelActions = useConfigNivelFluenciasActions();
    const escopoActions = useEscopoResponsabilidadeActions();

    const [contatoHistoricoEmpresas, setContatoHistoricoEmpresas] = useState<IContatoHistoricoEmpresa[]>([]);
    const [contatoHistoricoEmpresa, setContatoHistoricoEmpresa] = useState<IContatoHistoricoEmpresa>();

    const [tableData, setTableData] = useState<IHistorico[]>([]);
    const [value, setValue] = useState<IEmpresaType | null>(null);
    const [openEmpresaDialog, setOpenEmpresaDialog] = useState(false);
    const [dialogValue, setDialogValue] = useState({
        razaosocial: '',
        cnpj: '',
    });

    const [empresas, setEmpresas] = useState<IEmpresaType[]>([]);
    const [areasPosicao, setAreasPosicao] = useState<IAreaPosicao[]>([]);
    const [niveisPosicao, setNiveisPosicao] = useState<INivelPosicao[]>([]);
    const [escoposPosicao, setEscoposPosicao] = useState<IEscopoResonsabilidade[]>([]);

    const { control, handleSubmit, register, watch, reset } = useForm<IContatoHistoricoEmpresa>({
        defaultValues: {
            posicoes: [emptyPosicao]
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "posicoes"
    });

    const posicoesValue = watch("posicoes");

    const resetValues = () => {
        setContatoHistoricoEmpresa(undefined);
        setValue(null);
        reset({ posicoes: [emptyPosicao] });
    }

    const onSubmit = async (data: IContatoHistoricoEmpresa) => {
        if (!value) {
            alert('É necessário selecionar uma empresa');
            return;
        }
        setLoadingSave(true);

        if (!contatoHistoricoEmpresa)
            await adicionarNovoHistorico(data);
        else
            await editarHistorico(data);

        setLoadingSave(false);
        openDrawerHistorico(false);
        getContatoHistoricoEmpresas();
    };

    const adicionarNovoHistorico = async (data: IContatoHistoricoEmpresa) => {
        const historicoEmpresa = {
            contatoId: auth?.id,
            empresaId: value?.id
        }

        const resp = await contatoHistoricoEmpresaActions.create(historicoEmpresa);
        await data.posicoes?.forEach(async (posicao: IContatoHistoricoEmpresaPosicao) => {
            const req = {
                ...posicao,
                historicoEmpresaId: resp.id,
                dataInicio: posicao.dataInicio ? posicao.dataInicio.toISOString() : null,
                dataTermino: posicao.dataTermino ? posicao.dataTermino.toISOString() : null,
            }
            const posicaoResp = await contatoHistoricoEmpresaPosicoesActions.create(req);
            resp.posicoes.push(posicaoResp);
        });
        toast.success('Histórico cadastrado');
    }

    const editarHistorico = async (data: IContatoHistoricoEmpresa) => {
        const posicoesNovas: IContatoHistoricoEmpresaPosicao[] = [];
        const posicoesAlteradas: IContatoHistoricoEmpresaPosicao[] = [];
        const posicoesRemovidas: IContatoHistoricoEmpresaPosicao[] = [];

        data.posicoes?.forEach(posicao => {
            const req = {
                ...posicao,
                historicoEmpresaId: contatoHistoricoEmpresa!.id,
                dataInicio: posicao.dataInicio ? posicao.dataInicio.toISOString() : null,
                dataTermino: posicao.dataTermino ? posicao.dataTermino.toISOString() : null,
            } as any;
            if (posicao.id === 0)
                posicoesNovas.push(req);
            else if (contatoHistoricoEmpresa?.posicoes?.map(p => p.id)?.includes(posicao?.id))
                posicoesAlteradas.push(req);
            else
                posicoesRemovidas.push(posicao);
        });
        const newRequests = posicoesNovas.map(posicao => contatoHistoricoEmpresaPosicoesActions.create(posicao));

        const updateRequests = posicoesAlteradas.map(posicao => contatoHistoricoEmpresaPosicoesActions.update(posicao.id!, posicao));
        const deleteRequests = posicoesRemovidas.map(posicao => contatoHistoricoEmpresaPosicoesActions.delete(posicao.id!));

        await Promise.all([...newRequests, ...updateRequests, ...deleteRequests]);

        toast.success('Historico alterado');
    }

    const removerPosicao = (index: number) => {
        remove(index);
    }

    useEffect(() => {
        // if (!possuiPermissao(auth, ['configuracoes sistema'])) {
        //     toast.warn('Você não possui permissão para acessar esse módulo');
        //     history.push('/');
        // }
        setLoadingGet(true);
        getContatoHistoricoEmpresas();
        getEmpresas();
        getAreas();
        getNiveis();
        getEscopos();

        setLoading(false);
    }, []);

    const getContatoHistoricoEmpresas = () => {
        contatoHistoricoEmpresaActions
            .list(false, `?contatoId=${auth?.id}`)
            .then((data: IContatoHistoricoEmpresa[]) => {
                setContatoHistoricoEmpresas(data);

                const dataTable = data.map((c: IContatoHistoricoEmpresa) => {
                    const posicoesSorted = c.posicoes!.length > 0
                        ? c.posicoes?.sort((a, b) => new Date(a.dataInicio as any).getTime() - new Date(b.dataInicio as any).getTime())
                        : [];
                    return {
                        id: c.id,
                        empresa: c.empresaIdNavigation?.razaosocial,
                        dataInicio: posicoesSorted?.length ? formatDate(posicoesSorted[0]?.dataInicio) : null,
                        dataFim: posicoesSorted?.length ? formatDate(posicoesSorted[posicoesSorted.length - 1]?.dataTermino) : null,
                        atual: posicoesSorted?.length ? posicoesSorted[posicoesSorted.length - 1].cargoAtual.toString() : 'false',
                    } as IHistorico;
                });

                setTableData(dataTable);
                setLoadingGet(false);
            })
            .catch((error: any) => {
                setLoadingGet(false);
                toast.error(error)
            });
    }

    const formatDate = (date: Date | any) => {
        return moment(date).format('DD/MM/YYYY');
    }

    const getEmpresas = () => {
        empresaActions
            .list()
            .then((resp: any) => {
                setEmpresas(resp);
            })
            .catch((e: any) => {
                console.error(e);
                toast.error('Falha ao buscar empresas');
            });
    }

    const getAreas = () => {
        areaActions
            .list()
            .then((resp: any) => {
                setAreasPosicao(resp);
            })
            .catch((e: any) => {
                console.error(e);
                toast.error('Falha ao buscar Areas Posição');
            });
    }

    const getNiveis = () => {
        nivelActions
            .list()
            .then((resp: any) => {
                setNiveisPosicao(resp);
            })
            .catch((e: any) => {
                toast.error('Falha ao buscar Níveis Posição');
            });
    }

    const getEscopos = () => {
        escopoActions
            .list()
            .then((resp: any) => {
                setEscoposPosicao(resp);
            })
            .catch((e: any) => {
                toast.error('Falha ao buscar Escopos de Responsabilidades');
            });
    }

    const atualizarFormulario = (result: any) => {
        setLoadingForm(true);

        const contHist = contatoHistoricoEmpresas.find(c => c.id === result.id);
        if (contHist === undefined) return;

        setContatoHistoricoEmpresa(contHist);
        setValue(empresas.find(e => e.id === contHist!.empresaId)!);

        const posicoesMapped = contHist.posicoes!.map((element: IContatoHistoricoEmpresaPosicao) => ({
            ...element,
            dataInicio: dayjs(element.dataInicio?.toString()),
            dataTermino: dayjs(element.dataTermino?.toString())
        }));
        reset({ posicoes: posicoesMapped });
        openDrawerHistorico(true);
        setTimeout(() => {
            setLoadingForm(false);
        }, 1500);
    };

    const openDrawerHistorico = (result: any) => {
        setOpen(result);
        if (!result)
            resetValues();
    };

    const HandleApagar = (id: any) => {
        setLoading(true);
        contatoHistoricoEmpresaActions
            .delete(id)
            .then(() => {
                const sub = tableData.filter((f) => f.id !== id);
                setTableData(sub);
                const subContato = contatoHistoricoEmpresas.filter((f) => f.id !== id);
                setContatoHistoricoEmpresas(subContato);
                toast.success('Histórico removido com sucesso')
            })
            .catch((e: any) => toast.error('Falha ao remover histórico'))
            .finally(() => setLoading(false));
    };

    const AdicionarNaLista = () => {
        openDrawerHistorico(false);
    };

    const handleClose = () => {
        setDialogValue({
            razaosocial: '',
            cnpj: '',
        });
        setOpenEmpresaDialog(false);
    };

    const handleSubmitEmpresa = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!dialogValue.razaosocial || !dialogValue.cnpj) {
            toast.warn('Preencha todos os campos necessários');
            return;
        }
        const valueSubmit = {
            razaosocial: dialogValue.razaosocial,
            cnpj: dialogValue.cnpj,
        };
        empresaActions
            .create(valueSubmit)
            .then((data) => {
                setValue(data);
                toast.success('Empresa criada com sucesso');
            })
            .catch((e: any) => {
                console.error(e);
                toast.error('Falha ao salvar nova Empresa');
            });

        handleClose();
    };

    return (
        <>
            <Grid xs={12} md={12} sx={{ padding: "20px 0px" }}>
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Typography sx={{ padding: "10px" }} variant="h5" component="h2">
                        Histórico Profissional
                    </Typography>
                    <Button
                        style={{
                            padding: '0px',
                            height: '27px',
                            minWidth: '27px',
                            fontSize: '20px',
                            borderRadius: '50%',
                            marginTop: '10px',
                        }}
                        onClick={() => openDrawerHistorico(true)}
                        variant='contained'
                    >
                        +
                    </Button>
                </Grid>
                <Divider orientation="horizontal" />
            </Grid>

            <Grid container xs={12} md={12}>
                <Grid container xs={12} md={12} sx={{ padding: "10px", paddingBottom: "60px" }}>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">

                            <TableHead sx={{ minWidth: 650, backgroundColor: "#0e95ba85" }}>

                                <TableRow>
                                    <TableCell>Empresa</TableCell>
                                    <TableCell>Último Cargo / Posição</TableCell>
                                    <TableCell>Data Início</TableCell>
                                    <TableCell>Data Fim</TableCell>
                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody sx={{ backgroundColor: "#0392ca24" }}>
                                {contatoHistoricoEmpresas.map((row: IContatoHistoricoEmpresa) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.empresaIdNavigation.razaosocial}
                                        </TableCell>
                                        <TableCell>
                                            {
                                                // row.posicoes.length > 0 && areasPosicao.find((f) => f.id == row.posicoes[0].id! )?.nome
                                                // + ' - ' +
                                                niveisPosicao.find((f) => f.id == row.posicoes.find((f) => f.historicoEmpresaId == row.id)?.nivelId)?.nome
                                            }
                                        </TableCell>
                                        <TableCell>{row.posicoes.length > 0 ? new Date(row.posicoes.find((f) => f.historicoEmpresaId == row.id)!.dataInicio.toString()).toLocaleDateString() : ""}</TableCell>
                                        <TableCell>{row.posicoes.length > 0 ? new Date(row.posicoes.find((f) => f.historicoEmpresaId == row.id)!.dataTermino.toString()).toLocaleDateString() : ""}</TableCell>
                                        <TableCell>
                                            <Grid container xs={12} md={12}>
                                                <Grid container xs={6} md={6}>
                                                    <Icon
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => atualizarFormulario(row)}
                                                    >
                                                        {' '}
                                                        <EditIcon />{' '}
                                                    </Icon>
                                                </Grid>
                                                <Grid container xs={6} md={6}>
                                                    <Icon
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => HandleApagar(row.id!)}
                                                    >
                                                        {' '}
                                                        <DeleteIcon />{' '}
                                                    </Icon>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {loadingGet &&
                        <Grid container xs={12} md={12} sm={12} sx={{ display: 'flex' }}>
                            <LinearProgress sx={{ width: "100%" }} />
                        </Grid>
                    }
                </Grid>
            </Grid>

            <Drawer
                style={{ minWidth: '450px !important' }}
                anchor={'right'}
                open={open}
                onClose={() => openDrawerHistorico(false)}
            >
                <Grid item xs={12} md={12} style={{ padding: "90px 30px", maxWidth: "500px", width: "auto" }}>
                    <form onSubmit={handleSubmit(onSubmit)} style={{ minWidth: '450px' }}>

                        <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: "center" }}>
                            <Grid item xs={2} md={2}>
                                <IconDrawer onClick={() => openDrawerHistorico(false)}>close</IconDrawer>
                            </Grid>
                            <Grid item xs={10} md={10}>
                                <Typography variant="h5">Histórico Profissional</Typography>
                            </Grid>
                        </Grid>

                        <GridItemCadastro item xs={12} style={{ marginTop: '30px' }}>
                            <>
                                <Autocomplete
                                    value={value}
                                    disabled={(contatoHistoricoEmpresa?.id ?? 0) > 0}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            setTimeout(() => {
                                                setOpenEmpresaDialog(true);
                                                setDialogValue({
                                                    razaosocial: newValue,
                                                    cnpj: '',
                                                });
                                            });
                                        } else if (newValue && newValue.inputValue) {
                                            setOpenEmpresaDialog(true);
                                            setDialogValue({
                                                razaosocial: newValue.inputValue,
                                                cnpj: '',
                                            });
                                        } else {
                                            setValue(newValue);
                                        }
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        // if (params.inputValue !== '') {
                                        //     filtered.push({
                                        //         inputValue: params.inputValue,
                                        //         razaosocial: `Adicionar: "${params.inputValue}"`,
                                        //         cnpj: '',
                                        //     });
                                        // }
                                        return filtered;
                                    }}
                                    id='adicionar-empresa-dialog'
                                    options={empresas}
                                    getOptionLabel={(option) => {
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        return option.razaosocial;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    renderOption={(props, option) => (
                                        <li {...props}>{option.razaosocial}</li>
                                    )}
                                    fullWidth
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextFieldStato {...params} label='Empresa Cliente' />
                                    )}
                                />
                            </>
                        </GridItemCadastro>

                        {fields.map((field, index) => (
                            <>
                                <Accordion defaultExpanded key={index}>
                                    <AccordionSummary
                                        aria-controls='panel1d-content'
                                        id='panel1d-header'
                                    >
                                        <Typography>Cargo - {index + 1}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                            <Controller
                                                name={`posicoes.${index}.areaId` as const}
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControl fullWidth>
                                                        <InputLabel>Área</InputLabel>
                                                        <SelectStato {...field} label="Área">
                                                            {areasPosicao.map((area: IAreaPosicao) => (
                                                                <MenuItem value={area.id}>{area.nome}</MenuItem>
                                                            ))}
                                                        </SelectStato>
                                                    </FormControl>
                                                )}
                                            />
                                            <Controller
                                                name={`posicoes.${index}.nivelId` as const}
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControl fullWidth>
                                                        <InputLabel>Nível</InputLabel>
                                                        <SelectStato {...field} label="Nível">
                                                            {niveisPosicao.map((nivel: INivelPosicao) => (
                                                                <MenuItem value={nivel.id}>{nivel.nome}</MenuItem>
                                                            ))}
                                                        </SelectStato>
                                                    </FormControl>
                                                )}
                                            />
                                            <Controller
                                                name={`posicoes.${index}.escopoId` as const}
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControl fullWidth>
                                                        <InputLabel>Escopo</InputLabel>
                                                        <SelectStato {...field} label="Escopo">
                                                            {escoposPosicao.map((escopo: IEscopoResonsabilidade) => (
                                                                <MenuItem value={escopo.id}>{escopo.nome}</MenuItem>
                                                            ))}
                                                        </SelectStato>
                                                    </FormControl>
                                                )}
                                            />
                                            <TextFieldStato
                                                fullWidth
                                                label="Cargo"
                                                variant="outlined"
                                                {...register(`posicoes.${index}.cargo` as const)}
                                            />
                                            <Controller
                                                name={`posicoes.${index}.cargoAtual` as const}
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                {...field}
                                                                checked={field.value}
                                                            />
                                                        }
                                                        label="Cargo Atual"
                                                    />
                                                )}
                                            />
                                            <Controller
                                                name={`posicoes.${index}.dataInicio` as const}
                                                control={control}
                                                render={({ field }) => (
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDayjs}
                                                        localeText={{
                                                            clearButtonLabel: 'Empty',
                                                            todayButtonLabel: 'Now',
                                                        }}
                                                    >
                                                        <DesktopDatePicker
                                                            {...field}
                                                            label='Data Início'
                                                            format='DD/MM/YYYY'
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                            <Controller
                                                name={`posicoes.${index}.dataTermino` as const}
                                                control={control}
                                                render={({ field }) => (
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDayjs}
                                                        localeText={{
                                                            clearButtonLabel: 'Empty',
                                                            todayButtonLabel: 'Now',
                                                        }}
                                                    >
                                                        <DesktopDatePicker
                                                            {...field}
                                                            label='Data Término'
                                                            format='DD/MM/YYYY'
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                            <TextFieldStato
                                                fullWidth
                                                label="Superior (contato de referência)"
                                                variant="outlined"
                                                {...register(`posicoes.${index}.superior` as const)}
                                            />
                                            <TextFieldStato
                                                fullWidth
                                                label="Número de Subordinados"
                                                variant="outlined"
                                                type="number"
                                                {...register(`posicoes.${index}.numeroDeSubordinados` as const)}
                                            />
                                            <TextFieldStato
                                                fullWidth
                                                label="Responsabilidades"
                                                variant="outlined"
                                                multiline
                                                minRows={3}
                                                {...register(`posicoes.${index}.responsabilidades` as const)}
                                            />
                                            <TextFieldStato
                                                fullWidth
                                                label="Realizações"
                                                variant="outlined"
                                                multiline
                                                minRows={3}
                                                {...register(`posicoes.${index}.realizacoes` as const)}
                                            />
                                            <TextFieldStato
                                                fullWidth
                                                label="Motivo da Saída"
                                                variant="outlined"
                                                multiline
                                                {...register(`posicoes.${index}.motivoSaida` as const)}
                                            />
                                            <ButtonStato type="button" variant="contained" color="error" onClick={() => removerPosicao(index)}>
                                                Apagar
                                            </ButtonStato>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        ))}

                        <ButtonStato type="button" onClick={() => append(emptyPosicao)} sx={{ mt: 2 }}>
                            Adicionar Nova Posição
                        </ButtonStato>
                        <ButtonStato type="submit" variant="contained" sx={{ mt: 2 }}>
                            Enviar
                        </ButtonStato>
                    </form>
                </Grid>
            </Drawer >
        </>
    );
};

export default HistoricoProfissionalComponent;
