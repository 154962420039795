import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { styled } from 'styled-components';
import React from 'react';
import { authAtom } from '../../states/usuarios/AuthState';
import { GridContainer, TextFieldStato } from '../../commons/styleds/styleds';
import { CircularProgress, Divider, FormControl } from '@mui/material';
import { IAuth } from '../../commons/types/usuatios';
import { useUsuarioActions } from '../../states/usuarios/usuario.actions';
import EnderecosComponent from './Enderecos';
import TelefonesComponent from './Telefones';
import FormacaoAcademicaComponent from './FormacaoAcademica';
import IdiomasComponent from './Idiomas';
import HistoricoProfissionalComponent from './HistoricoProfissional';

const DadosPessoais: React.FC = () => {
    const auth = useRecoilValue(authAtom);
    const usuarioActions = useUsuarioActions();
    const [load, setLoad] = useState<boolean>(true);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const [assessorado, setAssessorado] = useState<IAuth>();


    const handleChangeNext = (e: any) => {
        setLoadingSave(true);
        usuarioActions.update(assessorado!.id!, assessorado!).then(() => {
            setTimeout(() => {
                setLoadingSave(false);
            }, 5000);
            toast.success("Salvo com sucesso !");

        }).catch((erro) => {
            toast.warn(erro);
            setLoadingSave(false);
        });
    };


    useEffect(() => {
        setAssessorado(auth!);
        usuarioActions.get(auth?.id!).then((resp: any) => {
            setAssessorado(resp);
            setLoad(false);
        }).catch((err: any) => {
            toast.warn(err)
            setLoad(false);
        });

    }, [auth]);

    return (
        <GridContainer container direction="row" spacing={0} sx={{ display: "flex", padding: "10px 15px 10px 10px", margin: "0px 30px !important", backgroundColor: "white" }}>
            <Grid container direction="row" spacing={0} sx={{ paddingTop: "25px", display: "flex", flexWrap: "wrap", alignContent: "flex-start" }} xs={12} md={12}>
                <Grid xs={12} md={12} sx={{ paddingBottom: "30px" }}>
                    <Typography sx={{ padding: "10px" }} variant="h5" component="h2">
                        Dados Pessoais
                    </Typography>
                    <Divider orientation="horizontal" />
                </Grid>

                {!load ?
                    <Grid container xs={12} md={12}>
                        <Grid container xs={12} md={6} sx={{ padding: "10px" }}>

                            <Grid container xs={12} md={12}>
                                <FormControl required fullWidth variant='outlined'>
                                    <FormInput
                                        onChange={(e: any) => {
                                            setAssessorado({
                                                ...assessorado,
                                                nome: e.target.value
                                            });
                                        }}
                                        value={assessorado?.nome}
                                        label='Nome'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container xs={12} md={12}>
                                <FormControl required fullWidth variant='outlined'>
                                    <FormInput
                                        onChange={(e: any) => {
                                            setAssessorado({
                                                ...assessorado,
                                                lastname: e.target.value
                                            });
                                        }}
                                        value={assessorado?.lastname}
                                        label='Sobrenome'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container xs={12} md={12}>
                                <FormControl required fullWidth variant='outlined'>
                                    <FormInput
                                        onChange={(e: any) => {
                                            setAssessorado({
                                                ...assessorado,
                                                maidenName: e.target.value
                                            });
                                        }}
                                        value={assessorado?.maidenName}
                                        label='Sobrenome'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container xs={12} md={12}>
                                <FormControl required fullWidth variant='outlined'>
                                    <FormInput
                                        onChange={(e: any) => {
                                            setAssessorado({
                                                ...assessorado,
                                                initials: e.target.value
                                            });
                                        }}
                                        value={assessorado?.initials}
                                        label='Iniciais'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container xs={12} md={12}>
                                <FormControl required fullWidth variant='outlined'>
                                    <FormInput
                                        onChange={(e: any) => {
                                            setAssessorado({
                                                ...assessorado,
                                                sufixo: e.target.value
                                            });
                                        }}
                                        value={assessorado?.sufixo}
                                        label='Sufixo'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container xs={12} md={12}>
                                <FormControl required fullWidth variant='outlined'>
                                    <FormInput
                                        onChange={(e: any) => {
                                            setAssessorado({
                                                ...assessorado,
                                                apelido: e.target.value
                                            });
                                        }}
                                        value={assessorado?.apelido}
                                        label='Apelido'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container xs={12} md={12}>
                                <FormControl required fullWidth variant='outlined'>
                                    <FormInput
                                        onChange={(e: any) => {
                                            setAssessorado({
                                                ...assessorado,
                                                email: e.target.value
                                            });
                                        }}
                                        value={assessorado?.email}
                                        label='Email'
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={6} sx={{ padding: "10px" }}>
                            <Grid container xs={12} md={12}>
                                <FormControl required fullWidth variant='outlined'>
                                    <FormInput
                                        onChange={(e: any) => {
                                            setAssessorado({
                                                ...assessorado,
                                                emailSecundario: e.target.value
                                            });
                                        }}
                                        value={assessorado?.emailSecundario}
                                        label='Email Secundário'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container xs={12} md={12}>
                                <FormControl required fullWidth variant='outlined'>
                                    <FormInput
                                        onChange={(e: any) => {
                                            setAssessorado({
                                                ...assessorado,
                                                emailProfissional: e.target.value
                                            });
                                        }}
                                        value={assessorado?.emailProfissional}
                                        label='Email Profissional'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container xs={12} md={12}>
                                <FormControl required fullWidth variant='outlined'>
                                    <FormInput
                                        onChange={(e: any) => {
                                            setAssessorado({
                                                ...assessorado,
                                                linkedin: e.target.value
                                            });
                                        }}
                                        value={assessorado?.linkedin}
                                        label='Linkedin'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container xs={12} md={12}>
                                <FormControl required fullWidth variant='outlined'>
                                    <FormInput
                                        onChange={(e: any) => {
                                            setAssessorado({
                                                ...assessorado,
                                                sexo: e.target.value
                                            });
                                        }}
                                        value={assessorado?.sexo}
                                        label='Sexo'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container xs={12} md={12}>
                                <FormControl required fullWidth variant='outlined'>
                                    <FormInput
                                        onChange={(e: any) => {
                                            setAssessorado({
                                                ...assessorado,
                                                skype: e.target.value
                                            });
                                        }}
                                        value={assessorado?.skype}
                                        label='Skype'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container xs={12} md={12}>
                                <FormControl required fullWidth variant='outlined'>
                                    <FormInput
                                        onChange={(e: any) => {
                                            setAssessorado({
                                                ...assessorado,
                                                cpf: e.target.value
                                            });
                                        }}
                                        value={assessorado?.cpf}
                                        label='CPF'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid container xs={12} md={12}>
                                <FormControl required fullWidth variant='outlined'>
                                    <FormInput
                                        onChange={(e: any) => {
                                            setAssessorado({
                                                ...assessorado,
                                                dtNascimento: e.target.value
                                            });
                                        }}
                                        value={assessorado?.dtNascimento}
                                        label='Data de Nascimento'
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container xs={12} md={12} sm={12} sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                            <ButtonHome
                                variant="contained"
                                disabled={loadingSave}
                                onClick={handleChangeNext}
                            >
                                {loadingSave &&
                                    <CircularProgress size={20} sx={{
                                        marginRight: "10px",
                                        color: "#b9b9b9"
                                    }} />
                                }
                                Salvar
                            </ButtonHome>
                        </Grid>
                        
                        <Grid container xs={12} md={12} sm={12} sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                            <Divider sx={{ marginTop: '20px' }} orientation="horizontal" />
                        </Grid>

                    </Grid> :
                    <>
                        <Grid container xs={12} md={12} sm={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress size={50} sx={{
                                marginRight: "10px"
                            }} />
                        </Grid>
                    </>
                }


                <EnderecosComponent />

                <TelefonesComponent />

                <FormacaoAcademicaComponent />

                <IdiomasComponent />

                <HistoricoProfissionalComponent />

            </Grid>
        </GridContainer >
    );
};

const ButtonHome = styled(Button)`
  background-color: #0091cf !important;
  color: white !important;
`;

const FormInput = styled(TextFieldStato)`
  margin-top: 20px !important;
`;

export default DadosPessoais;

