import { useBaseActions } from '../base.actions';
import { competenciasAtom } from './CompetenciaState';
import { useAxiosWrapper } from '../../services/axiosWrapper';
import { authAtom } from './../usuarios/AuthState';
import { history } from '../../helpers/history';
import { useRecoilValue, useSetRecoilState } from 'recoil';

function useCompetenciasActions() {
  const baseActions = useBaseActions('competencias', competenciasAtom, false);
  const axiosWrapper = useAxiosWrapper();
  const authState = useRecoilValue(authAtom);
  const setAuth = useSetRecoilState(authAtom);
 
  return {
    list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };

  async function list() {
    let url = `${process.env.REACT_APP_API_URL}/v1/assessorados/competencias`;

    try {
      const recursos = await axiosWrapper.get(url);

      return recursos;
    } catch (error) {
      if (error === 'Usuário inválido') {
        localStorage.removeItem('usuario');
        setAuth(null);
        history.push('/login');
      }
      throw error;
    }

  }
}

export { useCompetenciasActions };
