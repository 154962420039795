
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Checkbox, CircularProgress, Divider, Drawer, FormControl, FormControlLabel, Icon, LinearProgress, TableCell, TextField } from '@mui/material';
import { ITelefone } from '../../../commons/types/usuatios';
import { useTelefonesActions } from '../../../states/telefones/telefone.actions';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../../states/usuarios/AuthState';
import { ButtonStato, IconDrawer, TextFieldStato } from '../../../commons/styleds/styleds';
import { GridItemCadastroBtnSalvar } from '../../../commons/styleds/grids';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const empty: ITelefone = {
    id: 0,
    idContato: 0,
    idTipo: 3,
    nome: '',
    ddd: '',
    telefone: '',
    ramal: '',
    principal: false,
    ativo: true,
};

const TelefonesComponent: React.FC = () => {
    const telefoneActions = useTelefonesActions();
    const [telefones, setTelefones] = useState<ITelefone[]>([]);

    const auth = useRecoilValue(authAtom);
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingGet, setLoadingGet] = useState<boolean>(true);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const [telefone, setTelefone] = useState<ITelefone>(empty);

    useEffect(() => {
        setLoadingGet(true);
        telefoneActions.list()
            .then((resp: any) => {
                setTelefones(resp)
                setLoadingGet(false);
            })
            .catch((err: any) => {
                setLoadingGet(false);
                toast.warn(err)
            });
    }, []);


    const openDrawerTelefone = (result: any) => {
        setOpen(result);
    };

    const handleEditar = (item: ITelefone) => {
        setTelefone(item);
        openDrawerTelefone(true);
    };

    const handleApagar = (id: any) => {
        telefoneActions.delete(id).then(() => {
            const sub = telefones.filter((f) => f.id !== id);
            setTelefones(sub);
            toast.success('Apagado com sucesso!');
        });
    };

    const AdicionarNaLista = async () => {
        setLoadingSave(true);

        try {
            telefone.idTipo = 2;

            if ((!telefone?.idContato || telefone?.idContato <= 0) && Number(auth?.id) > 0) {
                telefone.idContato = Number(auth?.id);
            }

            let response: ITelefone;
            if (telefone.id && telefone.id > 0) {
                response = await telefoneActions.update(telefone.id, telefone);
                setTelefones((prevTelefones) => {
                    const newList = prevTelefones.filter((f) => f.id !== response.id);
                    return newList.concat(response);
                });

                toast.success('Telefone atualizado');
            } else {
                response = await telefoneActions.create(telefone);
                setTelefones((prevTelefones) => prevTelefones.concat(response));

                toast.success('Telefone salvo');
            }

            setTelefone(emptyTelefone());
            openDrawerTelefone(false);
        } catch (error) {
            console.error(error);
            toast.error('Erro ao salvar telefone');
        } finally {
            setLoadingSave(false);
        }
    };

    const emptyTelefone = () => {
        return {
            id: 0,
            idContato: Number(auth?.id),
            idTipo: 3,
            nome: '',
            ddd: '',
            telefone: '',
            ramal: '',
            principal: false,
            ativo: true,
        }
    }

    const handleTelefone = (event: any) => {
        if (event.target.value.length > 10) return '';
        event.target.value = event.target.value.replace(/\D/g, '');
        event.target.value = event.target.value.replace(/(\d)(\d{4})$/, '$1-$2');
        setTelefone({ ...telefone, telefone: event.target.value });
    };

    const handleDDD = (event: any) => {
        if (event.target.value.length > 5) return '';
        event.target.value = event.target.value.replace(/\D/g, '');
        event.target.value = event.target.value.replace(/(\d{2})(\d)/, '($1)');
        setTelefone({ ...telefone, ddd: event.target.value });
    };

    return (
        <>
            <Grid xs={12} md={12} sx={{ padding: "20px 0px" }}>
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Typography sx={{ padding: "10px" }} variant="h5" component="h2">
                        Telefones
                    </Typography>
                    <Button
                        style={{
                            padding: '0px',
                            height: '27px',
                            minWidth: '27px',
                            fontSize: '20px',
                            borderRadius: '50%',
                            marginTop: '10px',
                        }}
                        onClick={() => openDrawerTelefone(true)}
                        variant='contained'
                    >
                        +
                    </Button>
                </Grid>
                <Divider orientation="horizontal" />
            </Grid>

            <Grid container xs={12} md={12}>
                <Grid container xs={12} md={12} sx={{ padding: "10px" }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead sx={{ backgroundColor: "#0e95ba85" }}>
                                <TableRow>
                                    <TableCell>Apelido</TableCell>
                                    <TableCell>DDD</TableCell>
                                    <TableCell>Numero</TableCell>
                                    <TableCell>Ramal</TableCell>
                                    <TableCell>Principal</TableCell>
                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ backgroundColor: "#0392ca24" }}>
                                {telefones.map((row: ITelefone) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.nome}
                                        </TableCell>
                                        <TableCell>{row.ddd}</TableCell>
                                        <TableCell>{row.telefone}</TableCell>
                                        <TableCell>{row.ramal}</TableCell>
                                        <TableCell>{row.principal}</TableCell>
                                        <TableCell>
                                            <Grid container xs={12} md={12}>
                                                <Grid container xs={6} md={6}>
                                                    <Icon
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => handleEditar(telefones.find(f => f.id === row.id!)!)}
                                                    >
                                                        {' '}
                                                        <EditIcon />{' '}
                                                    </Icon>
                                                </Grid>
                                                <Grid container xs={6} md={6}>
                                                    <Icon
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => handleApagar(row.id!)}
                                                    >
                                                        {' '}
                                                        <DeleteIcon />{' '}
                                                    </Icon>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {loadingGet &&
                        <Grid container xs={12} md={12} sm={12} sx={{ display: 'flex' }}>
                            <LinearProgress sx={{ width: "100%" }} />
                        </Grid>
                    }
                </Grid>
            </Grid>

            <Drawer
                style={{ minWidth: '250px' }}
                anchor={'right'}
                open={open}
                onClose={() => openDrawerTelefone(false)}
            >
                <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ padding: '90px 30px', maxWidth: '500px', width: 'auto' }}
                >
                    <Grid
                        item
                        xs={12}
                        md={12}
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <Grid item xs={2} md={2}>
                            <IconDrawer onClick={() => openDrawerTelefone(false)}>
                                close
                            </IconDrawer>
                        </Grid>
                        <Grid item xs={10} md={10}>
                            <Typography variant='h5'>
                                {'Adicionar Telefone na Lista'}
                            </Typography>
                        </Grid>
                    </Grid>

                    <FormControl
                        id='cep'
                        required
                        fullWidth
                        variant='outlined'
                        sx={{ marginTop: '20px' }}
                    >
                        <TextFieldStato
                            type='text'
                            label='Nome'
                            value={telefone?.nome}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setTelefone({ ...telefone, nome: event.target.value });
                            }}
                        />
                    </FormControl>

                    <FormControl
                        required
                        fullWidth
                        variant='outlined'
                        sx={{ marginTop: '20px' }}
                    >
                        <TextFieldStato
                            id={'TelefoneInput'}
                            type='text'
                            label='DDD'
                            value={telefone?.ddd}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleDDD(event);
                            }}
                        />
                    </FormControl>

                    <FormControl
                        required
                        fullWidth
                        variant='outlined'
                        sx={{ marginTop: '20px' }}
                    >
                        <TextFieldStato
                            id={'TelefoneInput'}
                            type='numero'
                            label='Número'
                            value={telefone?.telefone}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleTelefone(event);
                            }}
                        />
                    </FormControl>

                    <FormControl
                        required
                        fullWidth
                        variant='outlined'
                        sx={{ marginTop: '20px' }}
                    >
                        <TextFieldStato
                            id={'TelefoneInput'}
                            type='text'
                            label='Ramal'
                            value={telefone?.ramal}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setTelefone({ ...telefone, ramal: event.target.value });
                            }}
                        />
                    </FormControl>

                    <FormControlLabel
                        required
                        sx={{ marginTop: '20px' }}
                        label="Principal"
                        control={
                            <Checkbox onClick={() => setTelefone({ ...telefone, principal: !telefone.principal })} />
                        }
                    />

                    {loadingSave && <LinearProgress />}

                    <GridItemCadastroBtnSalvar
                        item
                        xs={11}
                        md={11}
                        sx={{
                            position: 'fixed',
                            right: '0px',
                            bottom: '0px',
                        }}
                    >
                        <ButtonStato variant='contained' onClick={AdicionarNaLista}>
                            Adicionar
                        </ButtonStato>
                    </GridItemCadastroBtnSalvar>
                </Grid>
            </Drawer>
        </>
    );
};

export default TelefonesComponent;
