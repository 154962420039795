import { Box, FormControl, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { Divider } from '@mui/material';


interface Props {
  novoUsuario: boolean;
}

const PPCStepper4: React.FC<Props> = (props: Props) => {
  const { novoUsuario } = props;
  const methods = useFormContext();

  return (
    <>
      <Grid container xs={12} md={12} sm={12} sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
      }}>
        <Grid xs={12} md={12} sm={12}>
          <Typography sx={{ padding: "10px 0px" }} variant="h4" component="h2">
            Histórico de Carreira (Análise SWOT)
          </Typography>
        </Grid>

        <Grid container xs={12} md={12} sx={{ paddingBottom: "10px", display: "flex" }}>
          <Typography sx={{ padding: "10px" }} variant="caption">
            <b>Como usar a matriz SWOT na vida profissional</b>
          </Typography>
          <Typography sx={{ padding: "10px" }} variant="caption">
            Para realizar uma análise completa, escreva as respostas para cada uma das perguntas abaixo. Com estas informações, poderemos fazer uma análise cuidadosa e identificar suas forças e fraquezas, para então traçarmos estratégias de melhoria.
          </Typography>
        </Grid>


        <Grid container xs={12} md={12} sx={{ display: "flex" }}>
          <Typography sx={{ padding: "5px 0px" }} variant="caption">
            <b>Pontos Fortes:</b>
          </Typography>

          <Grid container xs={12} md={12} sx={{ padding: "0px 50px", paddingBottom: "5px", display: "flex" }}>
            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Dê um exemplo de um caso de sucesso onde seu conhecimento contribuiu significativamente para o resultado da empresa.
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotForteP1'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Quais aspectos ou experiências são diferenciais competitivos do seu perfil?
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotForteP2'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                O que você faz melhor do que a maioria das pessoas?
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotForteP3'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Aponte uma situação em que você ou seu departamento conseguiram aumentar a produtividade ou reduzir custos.
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotForteP4'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Conte uma situação de conflito em que você minimizou ou solucionou o problema.
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotForteP5'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Quais são suas competências mais marcantes?
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotForteP6'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Liste alguns feedbacks positivos recebidos dos seus gestores e equipes.
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotForteP7'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container xs={12} md={12} sx={{ paddingBottom: "10px", display: "flex" }}>
          <Typography sx={{ padding: "10px" }} variant="caption">
            O importante aqui é você ser perfeitamente honesto nas respostas. Se precisar de ajuda peça a algumas pessoas da sua confiança para apontarem estes pontos com maior precisão. <br /><br />

            Outra ressalva é observar o cenário no qual você está inserido - ser bom em matemática em um lugar onde todos são, não é uma vantagem e sim uma necessidade. Entretanto, ter um conhecimento profundo de informática numa área que possui computadores e nenhum técnico, pode ser visto como um ponto forte real.
          </Typography>
        </Grid>

        <Grid container xs={12} md={12} sx={{ display: "flex" }}>
          <Typography sx={{ padding: "5px 0px" }} variant="caption">
            <b>Fraco:</b>
          </Typography>

          <Grid container xs={12} md={12} sx={{ padding: "0px 50px", paddingBottom: "5px", display: "flex" }}>
            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Quais tarefas você evita no dia a dia e por quê?
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotFracoP1'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Compartilhe um caso de insucesso e o que faria diferente atualmente.
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotFracoP2'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Descreva algum feedback negativo recebido de seu gestor, par ou liderado.
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotFracoP3'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Quais habilidades técnicas você acredita que precisa desenvolver ou expandir.
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotFracoP4'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Quais habilidades comportamentais você acredita que precisa desenvolver ou expandir.
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotFracoP5'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Quais atitudes sabotam seu crescimento?
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotFracoP6'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Qual situação tira você do sério no local de trabalho?
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotFracoP7'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Como você lida com um dia ruim no trabalho?
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotFracoP8'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container xs={12} md={12} sx={{ paddingBottom: "10px", display: "flex" }}>
          <Typography sx={{ padding: "10px" }} variant="caption">
            Novamente peça para outras pessoas de confiança ajudarem no levantamento destas informações sobre você. Por mais duras que sejam as respostas, é preciso investigar os pontos que possam nos atrapalhar.
          </Typography>
        </Grid>

        <Grid container xs={12} md={12} sx={{ display: "flex" }}>
          <Typography sx={{ padding: "5px 0px" }} variant="caption">
            <b>Oportunidades:</b>
          </Typography>

          <Grid container xs={12} md={12} sx={{ padding: "0px 50px", paddingBottom: "5px", display: "flex" }}>
            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Sua área ou segmento está em expansão? Se sim, o que pode fazer para aproveitar essa situação?
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotOportunidadeP1'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Você se mantem atualizado nos temas relacionados à sua área de expertise (lifelong lerning)?
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotOportunidadeP2'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Como avalia a sua rede de relacionamento profissionais (networking)?
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotOportunidadeP3'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Está conectado com as novas tecnologias relacionadas à sua área?
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotOportunidadeP4'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Baseado em sua expertise, qual seria uma escolha disruptiva em sua carreira neste momento?
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotOportunidadeP5'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

          </Grid>
        </Grid>

        <Grid container xs={12} md={12} sx={{ display: "flex" }}>
          <Typography sx={{ padding: "5px 0px" }} variant="caption">
            <b>Ameaças:</b>
          </Typography>

          <Grid container xs={12} md={12} sx={{ padding: "0px 50px", paddingBottom: "5px", display: "flex" }}>
            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Quem ou o que pode interferir diretamente em seu sucesso profissional?
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotAmeacaP1'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                A tecnologia representa uma ameaça a sua carreira?
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotAmeacaP2'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Sua área pode ser transformada ou desaparecer em pouco tempo?
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotAmeacaP3'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Em que aspectos seus competidores são melhores que você?
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotAmeacaP4'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                5. Você possui muitos competidores na mesma área que você?
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotAmeacaP5'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

            <Grid xs={12} md={12} sm={12} sx={{ paddingBottom: "5px", width: "100%", marginTop: "10px" }}>
              <InputLabel sx={{
                whiteSpace: "normal"
              }}>
                Existe algum fator que pode atrapalhar a sua transição de carreira neste momento?
              </InputLabel >
              <Controller
                control={methods.control}
                name='analiseSwotAmeacaP6'
                render={({ field: { ref, ...field } }) => (
                  <TextField sx={{ width: "100%" }}
                    id="standard-basic"
                    {...field}
                    inputRef={ref}
                    value={field.value}
                    label=""
                    variant="standard"
                    multiline
                    maxRows={4}
                  />
                )}
              />
            </Grid>

          </Grid>
        </Grid>

        <Grid container xs={12} md={12} sx={{ paddingBottom: "10px", display: "flex" }}>
          <Typography sx={{ padding: "10px" }} variant="caption">
            Agora vamos a um exemplo de aplicação da <b>Matriz SWOT na vida profissional</b>. <br /><br />

            Insira as respostas dentro dos quadrantes abaixo para visualizar todos os pontos levantados nesta análise. Dessa forma, você irá visualizar seus inputs dentro na matriz, que funciona como um mapa mental.
          </Typography>
        </Grid>

        <Grid container direction="row" xs={12} md={12} sx={{ paddingBottom: "10px", display: "flex" }}>
          <Divider orientation="horizontal" />

          <Grid container xs={12} md={12} sm={12} sx={{ display: "flex" }}>
            <Grid container xs={6} md={6} sm={6} sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              color: "#4299e7",
              padding: "10px 5px 15px 5px",
              borderTop: "1px solid #4299e7",
              fontWeight: "500"
            }}>
              ÚTIL
            </Grid>
            <Grid container xs={6} md={6} sm={6} sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              color: "#d90000",
              padding: "10px 5px 15px 5px",
              borderTop: "1px solid #d90000",
              fontWeight: "500"
            }}>
              PREJUDICIAL
            </Grid>
          </Grid>

          <Grid container xs={12} md={12} sm={12} sx={{ display: "flex" }}>
            <Grid container xs={6} md={6} sm={6} sx={{ display: "flex" }}>
              <Grid container xs={12} md={12} sm={12} sx={{
                display: "flex",
                textAlign: "center",
                paddingBottom: "10px",
                backgroundColor: "#588bff",
                color: "white",
                borderRadius: "5px",
                padding: "10px",
                flexWrap: "wrap",
                justifyContent: "center",
                margin: "0px 10px 10px 10px"
              }}>
                FORÇAS
              </Grid>
              <Grid container xs={12} md={12} sm={12} sx={{ display: "flex", padding: "10px" }}>
                <Controller
                  control={methods.control}
                  name='analiseSwotForcaOrigem'
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      id="standard-multiline-static"
                      {...field}
                      inputRef={ref}
                      value={field.value}
                      label="Origem Interna"
                      multiline
                      rows={4}
                      variant="outlined"
                      sx={{ width: "100%" }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container xs={6} md={6} sm={6} sx={{ display: "flex" }}>
              <Grid container xs={12} md={12} sm={12} sx={{
                display: "flex",
                textAlign: "center",
                paddingBottom: "10px",
                backgroundColor: "#f75f25",
                color: "white",
                borderRadius: "5px",
                padding: "10px",
                flexWrap: "wrap",
                justifyContent: "center",
                margin: "0px 10px 10px 10px"
              }}>
                FRAQUEZAS
              </Grid>
              <Grid container xs={12} md={12} sm={12} sx={{ display: "flex", padding: "10px" }}>
                <Controller
                  control={methods.control}
                  name='analiseSwotOportunidadeOrigem'
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      id="standard-multiline-static"
                      {...field}
                      inputRef={ref}
                      value={field.value}
                      label="Origem Interna"
                      multiline
                      rows={4}
                      variant="outlined"
                      sx={{ width: "100%" }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container xs={12} md={12} sm={12} sx={{ display: "flex" }}>
            <Grid container xs={6} md={6} sm={6} sx={{ display: "flex" }}>
              <Grid container xs={12} md={12} sm={12} sx={{
                display: "flex",
                textAlign: "center",
                paddingBottom: "10px",
                backgroundColor: "#4dbd29",
                color: "white",
                borderRadius: "5px",
                padding: "10px",
                flexWrap: "wrap",
                justifyContent: "center",
                margin: "0px 10px 10px 10px"
              }}>
                OPORTUNIDADES
              </Grid>
              <Grid container xs={12} md={12} sm={12} sx={{ display: "flex", padding: "10px" }}>
                <Controller
                  control={methods.control}
                  name='analiseSwotFraquezaOrigem'
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      id="standard-multiline-static"
                      {...field}
                      inputRef={ref}
                      value={field.value}
                      label="Origem Externa"
                      multiline
                      rows={4}
                      variant="outlined"
                      sx={{ width: "100%" }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container xs={6} md={6} sm={6} sx={{ display: "flex" }}>
              <Grid container xs={12} md={12} sm={12} sx={{
                display: "flex",
                textAlign: "center",
                paddingBottom: "10px",
                backgroundColor: "#ffb329",
                color: "white",
                borderRadius: "5px",
                padding: "10px",
                flexWrap: "wrap",
                justifyContent: "center",
                margin: "0px 10px 10px 10px"
              }}>
                AMEAÇAS
              </Grid>
              <Grid container xs={12} md={12} sm={12} sx={{ display: "flex", padding: "10px" }}>
                <Controller
                  control={methods.control}
                  name='analiseSwotAmeacaOrigem'
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      id="standard-multiline-static"
                      {...field}
                      inputRef={ref}
                      value={field.value}
                      label="Origem Externa"
                      multiline
                      rows={4}
                      variant="outlined"
                      sx={{ width: "100%" }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>


        </Grid>

      </Grid >
    </>
  );
};

export default PPCStepper4;
