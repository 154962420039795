import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { styled } from 'styled-components';
import React from 'react';
import { history } from '../../helpers/history';
import { authAtom } from '../../states/usuarios/AuthState';
import { GridContainer } from '../../commons/styleds/styleds';
import LinearProgress from '@mui/material/LinearProgress';
import { Accordion, AccordionDetails, AccordionSummary, Divider } from '@mui/material';
import { useProgramasActions } from '../../states/programas/programa.actions';
import { IComandaEtapaReuniaoResponse, IReuniao } from '../../commons/types/programa';
import { Link } from 'react-router-dom';

// Função Utilitária
function createHTMLFromString(htmlString: any) {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    padding: 5,
    borderRadius: 5,
}));

const Programa: React.FC = () => {
    const auth = useRecoilValue(authAtom);
    const programasActions = useProgramasActions();

    const [expanded, setExpanded] = React.useState<number>(-1);
    const [porcetagem, setPorcetagem] = React.useState<number>(0);
    const [tipo, setTipo] = React.useState<string>("PLAN");

    const [reunioes, setReunioes] = useState<IComandaEtapaReuniaoResponse[]>([]);
    const [etapas, setEtapas] = useState<IReuniao[]>([]);

    const handleChange = (event: any) => {
        if (expanded == event) {
            setExpanded(-1);
        }
        else {
            setExpanded(event);
        }
    };

    const handleChangeTipo = (event: any) => {
        setTipo(event);
    };

    const handleChangeTipoLink = () => {
        history.push('/minha-transicao/programa/ppc');
    };



    const onSubmit = (e: any) => {
        e.preventDefault();


    };


    useEffect(() => {
        setReunioes(auth?.comanda.comandaEtapaReunioes);
        setEtapas(auth?.comanda.etapas);
        // if (auth!!) history.push('/');

    }, [auth]);

    useEffect(() => {
        let reunioCount = reunioes.length;
        let r = reunioes.filter((reuniao) => reuniao.status?.toLowerCase() != "agendada").length;

        setPorcetagem((r / reunioCount) * 100);

    }, [reunioes]);


    return (
        <GridContainer container direction="row" spacing={0} sx={{ display: "flex", padding: "10px 15px 10px 10px", margin: "0px 30px !important", backgroundColor: "white" }}>
            <Grid container direction="row" spacing={0} sx={{ paddingTop: "25px", display: "flex", flexWrap: "wrap", alignContent: "flex-start" }} xs={12} md={12}>
                <Grid xs={12} md={12} sx={{ paddingBottom: "30px" }}>
                    <Typography sx={{ padding: "10px" }} variant="h5" component="h2">
                        Programa Teste - STATO GO Completo
                    </Typography>
                    <Divider orientation="horizontal" />
                </Grid>

                <Grid container xs={12} md={12} sx={{ paddingBottom: "10px" }}>
                    <Grid container xs={12} md={12} sx={{ paddingBottom: "10px 0px" }}>
                        <Grid container xs={12} md={12}>
                            <Grid xs={6} md={6}>
                                Conclusão Prevista para: 21/08/2028
                            </Grid>
                            <Grid xs={6} md={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                Progresso atual: {porcetagem}%
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={12}>
                            <BorderLinearProgress variant="determinate" value={porcetagem} />
                        </Grid>
                    </Grid>
                </Grid>


                <Grid container xs={12} md={12} sx={{ paddingBottom: "10px" }}>
                    <Typography sx={{ padding: "10px" }} variant="caption">
                        Programa Teste
                    </Typography>
                </Grid>

                <Grid container xs={12} md={12} sx={{ padding: "10px 0px" }}>
                    <Divider orientation="horizontal" sx={{ width: "100%" }} />
                </Grid>

                <Grid container xs={12} md={12} direction="row" sx={{ paddingBottom: "10px" }}>
                    <Grid container xs={12} md={12} sx={{ padding: "10px 0px" }}>

                        {tipo == "PLAN" &&
                            <Grid container xs={12} md={12} id="tabLinks" className="nav nav-pills nav-wizard" sx={{ display: "flex", justifyContent: "center" }}>

                                <Grid id="_THINK" className="programaStatusPlan1" onClick={() => handleChangeTipo("THINK")}>
                                    <Grid data-toggle="tab">THINK</Grid>
                                </Grid>
                                <Grid id="_PLAN" className="programaStatusPlan2" onClick={() => handleChangeTipo("PLAN")}>
                                    <Grid data-toggle="tab">PLAN</Grid>
                                </Grid>
                                <Grid id="_GO-TO-MARKET" className="programaStatusPlan3" onClick={() => handleChangeTipo("GO_TO_MARKET")}>

                                    <Grid data-toggle="tab">GO TO MARKET</Grid>
                                </Grid>
                            </Grid>
                        }

                        {tipo == "THINK" &&
                            <Grid container xs={12} md={12} id="tabLinks" className="nav nav-pills nav-wizard" sx={{ display: "flex", justifyContent: "center" }}>

                                <Grid id="_THINK" className="programaStatusThink1" onClick={() => handleChangeTipo("THINK")}>
                                    <Grid data-toggle="tab">THINK</Grid>
                                </Grid>
                                <Grid id="_PLAN" className="programaStatusThink2" onClick={() => handleChangeTipo("PLAN")}>
                                    <Grid data-toggle="tab">PLAN</Grid>
                                </Grid>
                                <Grid id="_GO-TO-MARKET" className="programaStatusThink3" onClick={() => handleChangeTipo("GO_TO_MARKET")}>

                                    <Grid data-toggle="tab">GO TO MARKET</Grid>
                                </Grid>
                            </Grid>
                        }

                        {tipo == "GO_TO_MARKET" &&
                            <Grid container xs={12} md={12} id="tabLinks" className="nav nav-pills nav-wizard" sx={{ display: "flex", justifyContent: "center" }}>

                                <Grid id="_THINK" className="programaStatusGo1" onClick={() => handleChangeTipo("THINK")}>
                                    <Grid data-toggle="tab">THINK</Grid>
                                </Grid>
                                <Grid id="_PLAN" className="programaStatusGo2" onClick={() => handleChangeTipo("PLAN")}>
                                    <Grid data-toggle="tab">PLAN</Grid>
                                </Grid>
                                <Grid id="_GO-TO-MARKET" className="programaStatusGo3" onClick={() => handleChangeTipo("GO_TO_MARKET")}>

                                    <Grid data-toggle="tab">GO TO MARKET</Grid>
                                </Grid>
                            </Grid>
                        }

                    </Grid>
                </Grid>

                <Grid container xs={12} md={12} sx={{ padding: "10px 0px" }}>
                    <Divider orientation="horizontal" sx={{ width: "100%" }} />
                </Grid>

                <Grid container direction="row" xs={12} md={12} sx={{ display: "flex", padding: "20px 0px" }}>
                    {/* <Grid container xs={12} md={12} sx={{ display: "flex", padding: "20px 0px", justifyContent: "center" }}>
                        <h2 className='tituloReuniao'>PLAN</h2>
                    </Grid> */}

                    <Grid container xs={12} md={12}>

                        {etapas.filter((c) => c.idMetodologiaNavigation?.nome == tipo).map((etapa, index) => (

                            <Accordion expanded={expanded === index} onChange={() => handleChange(index)} sx={{ backgroundColor: "#389cff4d", width: "100%", margin: "5px" }}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{
                                    backgroundColor: "#1976d2",
                                    color: "white"
                                }}>
                                    <Grid container xs={12} md={12}>
                                        <Grid container xs={6} md={6}>
                                            <Typography>{etapa.nome}</Typography>
                                        </Grid>
                                        <Grid container xs={6} md={6} sx={{ display: "flex", justifyContent: "right" }}>
                                            {reunioes.length > 0 && ( reunioes.find((r) => r.tbEtapaId == etapa.id)?.status != null && reunioes.find((r) => r.tbEtapaId == etapa.id)?.status?.toLowerCase() != "agendada" ) ?
                                                <Grid sx={{ backgroundColor: "#73b027", padding: "2px 10px", borderRadius: "15px" }}>
                                                    <Typography>{reunioes.find((r) => r.tbEtapaId == etapa.id)?.status} {new Date(reunioes.find((r) => r.tbEtapaId == etapa.id)!.dtRealizadaFim!.toString()).toLocaleDateString()}</Typography>
                                                </Grid> :
                                                <Grid sx={{ backgroundColor: "#bbbbbb;", padding: "2px 10px", borderRadius: "15px" }}>
                                                    <Typography>{reunioes.find((r) => r.tbEtapaId == etapa.id)?.status ?? 'Pendente'}</Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        etapa.nome == "Análise de Perfil e Planejamento de Carreira" ?
                                            <Typography>
                                                <Grid container xs={12} md={12} sx={{ paddingBottom: "10px", display: "flex" }}>
                                                    <Typography sx={{ padding: "10px" }} variant="caption">
                                                        Tem como objetivo levantar informações importantes para seu Consultor conhecê-lo (a) melhor, além de ser essencial para uma reflexão estruturada e melhor planejamento de sua carreira.
                                                        <br /><br />

                                                        O material de <b>Realizações e Competências</b> ajudará nessa etapa do processo.
                                                        <br /><br />

                                                        Revisitar sua trajetória profissional e realizações, mapeando competências e oportunidades de melhoria, é a base da nossa metodologia para a construção do plano de ação para os próximos ciclos da sua carreira. Este material também será utilizado para a elaboração do currículo, definição de estratégias de posicionamento e comunicação.
                                                        <br /><br />

                                                        Se tiver alguma dificuldade para o preenchimento, acesse nossa <b>FAQ do PPC</b> e veja se consegue tirar suas dúvidas.
                                                        <br /><br />

                                                        Concentre-se, clique no link abaixo para preencher seu perfil e bom trabalho!
                                                        <br /><br />

                                                        <Grid onClick={handleChangeTipoLink}>
                                                            <Link to="/minha-transicao/programa/ppc">Clique aqui para preencher o formulário </Link>
                                                            Etapa concluída </Grid>
                                                    </Typography>
                                                </Grid>
                                            </Typography>
                                            :
                                            <Typography>
                                                {createHTMLFromString(etapa.texto)}
                                            </Typography>
                                    }
                                </AccordionDetails>
                            </Accordion>

                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </GridContainer>

    );
};

const ButtonHome = styled(Button)`
  background-color: #0091cf !important;
  color: white !important;
`;


export default Programa;
