import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { styled } from 'styled-components';
import React from 'react';
import { authAtom } from '../../states/usuarios/AuthState';
import { useUsuarioActions } from '../../states/usuarios/usuario.actions';
import { GridContainer } from '../../commons/styleds/styleds';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LinearProgress from '@mui/material/LinearProgress';
import { Checkbox } from '@mui/material';
import { IComanda } from '../../commons/types/crm';
import { useReuniaosActions } from '../../states/reuniao/reuniao.actions';
import { IConsultor } from '../../commons/types/usuatios';
import { useMenusActions } from '../../states/menus/menu.actions';
import { toast } from 'react-toastify';
import { history } from '../../helpers/history';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
}));

const MinhaTransicao: React.FC = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    'https://one.statobr.com/content/files/conteudo_STATOGO.jpg',
    'https://one.statobr.com/content/files/conteudo_Introdu%C3%A7%C3%A3o_1.gif',
    'https://one.statobr.com/content/files/conteudo_microphone-2170045_640_(1).jpg',
    'https://one.statobr.com/content/files/conteudo_businessmen-384741_1280.jpg'
  ];

  const clientes = [
    "https://portal.statobr.com/assets/img/clients/brasil_foods.png",
    "https://portal.statobr.com/assets/img/clients/goodyear.png",
    "https://portal.statobr.com/assets/img/clients/heineken.png",
    "https://portal.statobr.com/assets/img/clients/hering.png",
    "https://portal.statobr.com/assets/img/clients/johnson_e_johnson.png",
    "https://portal.statobr.com/assets/img/clients/lenovo.png",
    "https://portal.statobr.com/assets/img/clients/lexmark.png",
    "https://portal.statobr.com/assets/img/clients/nestle.png",
    "https://portal.statobr.com/assets/img/clients/novo_nordisk.png",
    "https://portal.statobr.com/assets/img/clients/pepsico.png",
    "https://portal.statobr.com/assets/img/clients/pirelli.png",
    "https://portal.statobr.com/assets/img/clients/telefonica.png"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const auth = useRecoilValue(authAtom);
  const usuarioActions = useUsuarioActions();
  const reuniaoActions = useReuniaosActions();
  const [email, setEmail] = useState<string>('');
  const [senha, setSenha] = useState<string>('');
  const [comanda, setComanda] = useState<IComanda>();
  const [consultorMaster, setConsultorMaster] = useState<IConsultor>();

  const [mostrarSenha, setMostrarSenha] = React.useState(false);

  const handleClickMostrarSenha = () => setMostrarSenha((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

  };

  const rows = [
    { "nome": "Perfil e Planejamento de Carreira", "ativo": true },
    { "nome": "Apresentação do programa", "ativo": true },
    { "nome": "Cartões de Visita", "ativo": true },
  ];

  const rowsF = [
    { "nome": "Negociação e análise de propostas", "ativo": false },
    { "nome": "Encerramento do processo", "ativo": false },
    { "nome": "Coaching de integração", "ativo": false },
  ];

  useEffect(() => {
    setComanda(auth?.comanda);
    setConsultorMaster(auth?.consultorResponsavel);

  }, [auth]);

  useEffect(() => {

    //if (auth!!) history.push('/');

  }, [auth]);

  return (
    <GridContainer container direction="row" spacing={0} sx={{ display: "flex", padding: "10px 20px 10px 20px", }}>
      <Grid container direction="row" spacing={0} sx={{ paddingTop: "25px", display: "flex", flexWrap: "wrap", alignContent: "flex-start" }} xs={12} md={12}>


        <Grid container direction="row" spacing={0} sx={{ paddingTop: "25px" }} xs={12} md={12}>
          <Grid container xs={12} md={9} sx={{ width: "100%", }}>
            <Grid sx={{ width: "100%", maxHeight: 450 }}>
              {/* <img src={banner} alt='logo' width={'100%'} /> */}
              <img src={images[currentImageIndex]} alt={`Banner ${currentImageIndex}`} width={'100%'} height={'100%'} />
              {/* <Grid xs={12} md={12}>
            <img src={images[currentImageIndex]} alt={`Banner ${currentImageIndex}`} />
            <Button onClick={() => setCurrentImageIndex((prevIndex) => prevIndex === 0 ? images.length - 1 : prevIndex - 1)}>
              Previous
            </Button>
            <Button onClick={() => setCurrentImageIndex((prevIndex) => prevIndex === images.length - 1 ? 0 : prevIndex + 1)}>
              Next
            </Button>
          </Grid> */}
            </Grid>
          </Grid>

          {consultorMaster?.nome &&
            <Grid container xs={12} md={3} sx={{ display: "flex" }}>
              <Grid item xs={12} md={12} sx={{
                display: "flex",
                justifyContent: "center"
              }}>
                <Grid sx={{
                  textAlign: "center",
                  flexWrap: "wrap",
                  paddingTop: "90px"
                }}>
                  <Grid item xs={12} md={12}>

                    <Grid container sx={{
                      display: "flex",
                      backgroundColor: "#0091cf",
                      width: "255px",
                      height: "200px",
                      borderRadius: "10px",
                    }}>
                      <Grid xs={12} md={12}>
                        <img src='https://one.statobr.com/content/files/conteudo_Avatar_laranja.jpg' alt=""
                          style={{
                            borderRadius: "200px",
                            width: "120px",
                            height: "120px",
                            backgroundSize: "190px",
                            backgroundPosition: "center",
                            border: "10px solid #0091cf",
                            position: "relative",
                            bottom: "45%",
                          }}
                        />
                      </Grid>

                      <Grid xs={12} md={12} style={{ marginTop: "-80px", color: "white" }}>
                        <Typography variant="h6">
                          Seu Consultor
                        </Typography>
                        <Typography variant="body1">
                          {consultorMaster?.nome}
                        </Typography>
                        <Typography variant="body1">
                          {consultorMaster?.email}
                        </Typography>
                        <Typography variant="body1">
                          {consultorMaster?.celular}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>


        <Grid container sx={{ display: "flex" }} item xs={12} md={9} spacing={0}>
          <Grid container sx={{ textAlign: "center", display: "flex", }} item xs={12} md={12}>
            <Grid sx={{ padding: "15px", marginTop: "20px", backgroundColor: "white", borderRadius: "30px", border: "2px solid #dddddd", }} item xs={12} md={6}>
              <Typography sx={{ padding: "10px" }} variant="h5" component="h2">
                Bem Vindo(a) Admin
              </Typography>

              <Grid xs={12} md={12}>
                O que você quer fazer ?
              </Grid>

              <ButtonHome sx={{ marginTop: "20px" }}>ACESSAR A ETAPA ATUAL DO MEU PROGRAMA</ButtonHome>

              <ButtonHome onClick={() => history.push('/minha-transicao/programa')} sx={{ marginTop: "10px" }}>VER O MEU PROGRAMA TODO E ESCOLHER UMA ETAPA</ButtonHome>
            </Grid>
            <Grid container sx={{ padding: "15px", marginTop: "20px", backgroundColor: "white", borderRadius: "30px", border: "2px solid #dddddd", }} item xs={12} md={6}>
              <Grid xs={12} md={12}>
                <Typography sx={{ padding: "10px" }} variant="h5" component="h2">
                  Minhas Estatísticas
                </Typography>
              </Grid>

              <Grid xs={6} md={4}>
                <Grid xs={12} md={12}>
                  <IconButton
                  // sx={{ color: 'grey' }}
                  // onClick={handleClickDrawer}
                  // hidden={true}
                  >
                    <Grid>
                      <ChatBubbleIcon sx={{
                        fontSize: "60px"
                      }} />
                      <span style={{
                        fontSize: "17px",
                        fontWeight: "700",
                        color: "white",
                        position: "absolute",
                        left: "50%",
                        transform: "translate(-50%, 0px)",
                        top: "28%",
                      }}>28</span>
                    </Grid>
                  </IconButton>
                </Grid>
                <Grid xs={12} md={12}>
                  Etapas Concluídas
                </Grid>

              </Grid>
              <Grid xs={6} md={4}>
                <Grid xs={12} md={12}>
                  <IconButton
                  // sx={{ color: 'grey' }}
                  // onClick={handleClickDrawer}
                  // hidden={true}
                  >
                    <Grid>
                      <ChatBubbleIcon sx={{
                        fontSize: "60px"
                      }} />
                      <span style={{
                        fontSize: "17px",
                        fontWeight: "700",
                        color: "white",
                        position: "absolute",
                        left: "50%",
                        transform: "translate(-50%, 0px)",
                        top: "28%",
                      }}>87466</span>
                    </Grid>
                  </IconButton>
                </Grid>
                <Grid xs={12} md={12}>
                  Empresas Selecionadas
                </Grid>
              </Grid>
              <Grid xs={6} md={4}>
                <Grid xs={12} md={12}>
                  <IconButton
                  // sx={{ color: 'grey' }}
                  // onClick={handleClickDrawer}
                  // hidden={true}
                  >
                    <Grid>
                      <ChatBubbleIcon sx={{
                        fontSize: "60px"
                      }} />
                      <span style={{
                        fontSize: "17px",
                        fontWeight: "700",
                        color: "white",
                        position: "absolute",
                        left: "50%",
                        transform: "translate(-50%, 0px)",
                        top: "28%",
                      }}>11260</span>
                    </Grid>
                  </IconButton>
                </Grid>
                <Grid xs={12} md={12}>
                  HeadHunters Selecionados
                </Grid>
              </Grid>

              <Grid xs={6} md={4}>
                <Grid xs={12} md={12}>
                  <IconButton
                  // sx={{ color: 'grey' }}
                  // onClick={handleClickDrawer}
                  // hidden={true}
                  >
                    <Grid>
                      <ChatBubbleIcon sx={{
                        fontSize: "60px"
                      }} />
                      <span style={{
                        fontSize: "17px",
                        fontWeight: "700",
                        color: "white",
                        position: "absolute",
                        left: "50%",
                        transform: "translate(-50%, 0px)",
                        top: "28%",
                      }}>419</span>
                    </Grid>
                  </IconButton>
                </Grid>
                <Grid xs={12} md={12}>
                  Oportunidades
                </Grid>
              </Grid>
              <Grid xs={6} md={4}>
                <Grid xs={12} md={12}>
                  <IconButton
                  // sx={{ color: 'grey' }}
                  // onClick={handleClickDrawer}
                  // hidden={true}
                  >
                    <Grid>
                      <CheckCircleIcon sx={{
                        fontSize: "60px"
                      }} />
                    </Grid>
                  </IconButton>
                </Grid>
                <Grid xs={12} md={12}>
                  Curriculo cadastrado
                </Grid>
              </Grid>
              <Grid xs={6} md={4}>
                <Grid xs={12} md={12}>
                  <IconButton
                  // sx={{ color: 'grey' }}
                  // onClick={handleClickDrawer}
                  // hidden={true}
                  >
                    <Grid>
                      <ChatBubbleIcon sx={{
                        fontSize: "60px"
                      }} />
                      <span style={{
                        fontSize: "17px",
                        fontWeight: "700",
                        color: "white",
                        position: "absolute",
                        left: "50%",
                        transform: "translate(-50%, 0px)",
                        top: "28%",
                      }}>0</span>
                    </Grid>
                  </IconButton>
                </Grid>
                <Grid xs={12} md={12}>
                  Eventos
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ textAlign: "center", display: "flex" }} item xs={12} md={12}>
            <Grid sx={{ padding: "15px", marginTop: "20px", backgroundColor: "white", borderRadius: "30px", border: "2px solid #dddddd", }} item xs={12} md={6}>
              <Grid xs={12} md={12}>
                <Typography sx={{ padding: "10px" }} variant="h5" component="h2">
                  Reuniões Realizadas
                </Typography>
              </Grid>

              <Grid>
                <TableContainer sx={{ height: "240px !important" }} component={Paper}>
                  <Table sx={{ maxHeight: "240px" }} size="small" aria-label="a dense table">
                    {/* <TableHead>
                    <TableRow>
                      <TableCell>Dessert (100g serving)</TableCell>
                      <TableCell align="right">Calories</TableCell>
                      <TableCell align="right">Fat&nbsp;(g)</TableCell>
                      <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                      <TableCell align="right">Protein&nbsp;(g)</TableCell>
                    </TableRow>
                  </TableHead> */}
                    <TableBody>
                      {comanda?.comandaEtapaReunioes?.filter((f) => f.status == "APROVADA" || f.status == "PENDENTE_APROVACAO").map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>
                            <Checkbox
                              checked={true}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {
                              comanda.etapas?.find((f) => f.id == row.tbEtapaId)?.nome
                            }
                          </TableCell>
                          {/* <TableCell align="right">{row.calories}</TableCell>
                        <TableCell align="right">{row.fat}</TableCell>
                        <TableCell align="right">{row.carbs}</TableCell>
                        <TableCell align="right">{row.protein}</TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>


            </Grid>
            <Grid sx={{ padding: "15px", marginTop: "20px", backgroundColor: "white", borderRadius: "30px", border: "2px solid #dddddd", }} item xs={12} md={6}>
              <Grid xs={12} md={12}>
                <Typography sx={{ padding: "10px" }} variant="h5" component="h2">
                  Próximas Reuniões Agendadas
                </Typography>
              </Grid>

              <Grid>
                <TableContainer sx={{ height: "240px !important" }} component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    {/* <TableHead>
                    <TableRow>  
                      <TableCell>Dessert (100g serving)</TableCell>
                      <TableCell align="right">Calories</TableCell>
                      <TableCell align="right">Fat&nbsp;(g)</TableCell>
                      <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                      <TableCell align="right">Protein&nbsp;(g)</TableCell>
                    </TableRow>
                  </TableHead> */}
                    <TableBody>
                      {comanda?.comandaEtapaReunioes?.filter((f) => f.status == "AGENDADA").map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>
                            <Checkbox
                              checked={false}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {
                              comanda.etapas?.find((f) => f.id == row.tbEtapaId)?.nome
                            }
                          </TableCell>
                          {/* <TableCell align="right">{row.calories}</TableCell>
                        <TableCell align="right">{row.fat}</TableCell>
                        <TableCell align="right">{row.carbs}</TableCell>
                        <TableCell align="right">{row.protein}</TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>

        </Grid >

        <Grid container item xs={12} md={3} sx={{ display: "flex", textAlign: "center", flexWrap: "wrap", alignContent: "flex-start" }}>
          <Grid item xs={12} md={12}>
            <Grid container sx={{ padding: "15px", marginTop: "20px", height: "321px", backgroundColor: "white", borderRadius: "30px", border: "2px solid #dddddd", }} item xs={12} md={12}>
              <Grid xs={12} md={12} sx={{ textAlign: "center" }}>
                <Typography sx={{ padding: "10px" }} variant="h5" component="h2">
                  Oportunidades Recentes
                </Typography>
              </Grid>



            </Grid>
          </Grid>
          <Grid container item xs={12} md={12}>
            <Grid container sx={{ padding: "15px", marginTop: "20px", backgroundColor: "white", borderRadius: "30px", border: "2px solid #dddddd", }} item xs={12} md={12}>
              <Grid xs={12} md={12}>
                <Typography sx={{ padding: "10px" }} variant="h5" component="h2">
                  Pesquisa
                </Typography>
              </Grid>

              <Grid xs={12} md={12}>
                <Typography variant="body1" sx={{ fontWeight: "500" }}>
                  Carreira
                </Typography>
              </Grid>

              <Grid xs={12} md={12} sx={{ padding: "5px" }}>
                Você recomendaria seu último empregador para pessoas conhecidas?
              </Grid>
              <Grid xs={12} md={12} sx={{ padding: "5px" }}>
                Sim 62%
                <BorderLinearProgress variant="determinate" value={62} />
              </Grid>
              <Grid xs={12} md={12} sx={{ padding: "5px" }}>
                Talvez 17%
                <BorderLinearProgress variant="determinate" value={17} />
              </Grid>
              <Grid xs={12} md={12} sx={{ padding: "5px" }}>
                Não 19%
                <BorderLinearProgress variant="determinate" value={19} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container sx={{ textAlign: "center", display: "flex" }} item xs={12} md={12}>
          <Grid sx={{ padding: "15px", marginTop: "20px", backgroundColor: "white", borderRadius: "30px", border: "2px solid #dddddd", }} item xs={12} md={12}>
            <Grid container xs={12} md={12}>
              <Grid container xs={2} md={2} sx={{ display: "flex" }}>
              </Grid>

              <Grid container xs={10} md={10}>
                <Grid container xs={12} md={12} sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                  <Typography sx={{ padding: "10px" }} variant="h5" component="h2">
                    Depoimentos
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container xs={12} md={12} sx={{ paddingRight: "15px" }}>
              <Grid container xs={2} md={2} sx={{ display: "flex" }}>
                <Grid container xs={12} md={12} sx={{ display: "flex", flexWrap: "wrap", alignContent: "flex-start" }}>
                  <Grid xs={12} md={12} sx={{}}>
                    <img alt="" src="https://portal.statobr.com/assets/img/testimonials/user.jpg" width={80} style={{ borderRadius: "40px" }} />
                  </Grid>
                  <Grid xs={12} md={12} sx={{}}>
                    <span>
                      Aluisio Braga Ribeiro Junior
                    </span>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container xs={10} md={10} sx={{
                maxHeight: 350, overflowY: "auto", padding: "0px 20px", marginBottom: "20px", border: "2px solid #e9e9e9", borderRadius: "13px",
                backgroundColor: "#0191cd73"
              }}>
                <Grid xs={12} md={12}>
                  <p className='balao'>
                    "Esta é uma empresa extremamente profissional e absolutamente capacitada para suportar os profissionais em transição de carreira.” Ex Gerente da Lear do Brasil.
                  </p>
                </Grid>
                <Grid xs={12} md={12}>
                  <p className='balao'>
                    "A assessoria da STATO foi muito importante. Quando você se vê desempregado e praticamente sem uma direção, você precisa muito de alguém para conversar e que não seja do seu ambiente, e eu encontrei na minha consultora uma pessoa profissional mas que também soube escutar e fazer com que nosso trabalho de recolocação fosse um momento que me fazia muito bem.....por isso, me recoloquei rápido. Obrigado!"
                  </p>
                </Grid>
                {/* <Grid xs={12} md={12}>
                <p className='balao'>
                  "Esta é uma empresa extremamente profissional e absolutamente capacitada para suportar os profissionais em transição de carreira.” Ex Gerente da Lear do Brasil.
                </p>
              </Grid>
              <Grid xs={12} md={12}>
                <p className='balao'>
                  "Esta é uma empresa extremamente profissional e absolutamente capacitada para suportar os profissionais em transição de carreira.” Ex Gerente da Lear do Brasil.
                </p>
              </Grid>
              <Grid xs={12} md={12}>
                <p className='balao'>
                  "Esta é uma empresa extremamente profissional e absolutamente capacitada para suportar os profissionais em transição de carreira.” Ex Gerente da Lear do Brasil.
                </p>
              </Grid>
              <Grid xs={12} md={12}>
                <p className='balao'>
                  "Esta é uma empresa extremamente profissional e absolutamente capacitada para suportar os profissionais em transição de carreira.” Ex Gerente da Lear do Brasil.
                </p>
              </Grid> */}
              </Grid>
            </Grid>

          </Grid>
        </Grid>

        <Grid container sx={{ textAlign: "center", display: "flex" }} item xs={12} md={12}>
          <Grid sx={{ padding: "15px", marginTop: "20px", backgroundColor: "white", borderRadius: "30px", border: "2px solid #dddddd", }} item xs={12} md={12}>

            <Grid xs={12} md={12}>
              <Typography sx={{ padding: "10px" }} variant="h5" component="h2">
                Nossos Clientes
              </Typography>
            </Grid>

            <Grid container xs={12} md={12} sx={{ overflowX: "auto", display: "flex" }}>
              <Grid xs={12} md={12} sx={{ overflowX: "auto", display: "flex" }}>
                {clientes.map((image, index) => (
                  <Grid xs={2} md={2} sm={2}>
                    <img src={image} alt={`Image ${index + 1}`} />
                  </Grid>
                ))}
              </Grid>
            </Grid>


          </Grid>
        </Grid>

        <Grid sx={{ display: "flex", height: "50px" }} xs={12} md={12}>
        </Grid>


      </Grid>
    </GridContainer >
  );
};

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 6,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

const ButtonHome = styled(Button)`
  background-color: #0091cf !important;
  color: white !important;
`;

export default MinhaTransicao;
