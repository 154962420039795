import { withRouter } from 'react-router-dom';
import Logo from '../../assets/Logo-Stato-Intoo_1.png';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import { useEffect, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Drawer, DrawerHeader, BoxMenu } from './MenuStyle';
import { history } from '../../helpers/history';
import React from 'react';
import Icon from '@mui/material/Icon';
import { styled } from 'styled-components';
import useTheme from '@mui/material/styles/useTheme';
import Header from './header';
import ExpandMore from "@mui/icons-material/ExpandLess";
import ExpandLess from "@mui/icons-material/ExpandMore";
import { useUsuarioActions } from '../../states/usuarios/usuario.actions';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../states/usuarios/AuthState';
import { toast } from 'react-toastify';
import Collapse from "@mui/material/Collapse";
import { useMenusActions } from '../../states/menus/menu.actions';
import { IMenu } from '../../commons/types/base';

interface IModulo {
  privilegio: string;
  nome: string;
  icon: string;
  link: string;
  filhos?: IModulo[];
}

const MenuBar: React.FC = (props: any) => {
  const { children } = props;
  const path = window.location.pathname;
  const usuarioActions = useUsuarioActions();
  const auth = useRecoilValue(authAtom);
  const [open, setOpen] = useState(false);
  const [pathlink, setPathlink] = useState("");
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [menuItens, setMenuItens] = useState<IModulo[]>([

    {
      privilegio: 'Minha Transição',
      icon: 'dashboardRounded',
      nome: 'Minha Transição',
      link: '/minha-transicao',
      filhos: [
        {
          privilegio: 'Ações',
          icon: 'dashboardRounded',
          nome: 'Ações',
          link: 'acoes',
        },
        {
          privilegio: 'Dados Pessoais',
          icon: 'dashboardRounded',
          nome: 'Dados Pessoais',
          link: 'dados-pessoais',
        },
        {
          privilegio: 'Programa',
          icon: 'dashboardRounded',
          nome: 'Programa',
          link: 'programa',
        },
      ]
    },
    {
      privilegio: 'Oportunidades',
      icon: 'eventNoteRounded',
      nome: 'Oportunidades',
      link: '/oportunidades',
      filhos: []
    },
    {
      privilegio: 'Recursos',
      icon: 'groupsRounded',
      nome: 'Recursos',
      link: '/recursos',
      filhos: [
        {
          privilegio: 'Materiais de Apoio',
          icon: 'dashboardRounded',
          nome: 'Materiais de Apoio',
          link: '/',
          filhos: [
            {
              privilegio: 'Cursos Gratuitos',
              icon: 'dashboardRounded',
              nome: 'Cursos Gratuitos',
              link: '/',
            },
          ]
        },
      ]
    },
    {
      privilegio: 'Eventos',
      icon: 'settingsRounded',
      nome: 'Eventos',
      link: '/eventos',
      filhos: []
    },
    {
      privilegio: 'Empreenderorismo',
      icon: 'settingsRounded',
      nome: 'Empreenderorismo',
      link: '/empreenderorismo',
      filhos: []
    },
    {
      privilegio: 'Notícias',
      icon: 'settingsRounded',
      nome: 'Notícias',
      link: '/noticias',
      filhos: []
    },
    {
      privilegio: 'Sair',
      icon: 'exit_to_appRounded',
      nome: 'Sair',
      link: '/exit',
      filhos: []
    },
  ]);

  const theme = useTheme();
  const [menuItenFilhosAbertos, setMenuItenFilhosAbertos] =
    React.useState<string>("");

  const menusActions = useMenusActions();

  useEffect(() => {
    if (!auth || !auth.token) {
      localStorage.removeItem('usuario');
      history.push('/login');
    }

    menusActions.get().then((d) => {
      let menus: IMenu = d.resultado;

      let estrutura = menus.areas.filter(area => area.idParent === null);
      estrutura = estrutura.filter(area => area.nome !== 'Minha Transição');

      let modulos: IModulo[] = [{
        privilegio: 'Minha Transição',
        icon: 'dashboardRounded',
        nome: 'Minha Transição',
        link: '/minha-transicao',
        filhos: [
          {
            privilegio: 'Ações',
            icon: 'dashboardRounded',
            nome: 'Ações',
            link: 'acoes',
          },
          {
            privilegio: 'Dados Pessoais',
            icon: 'dashboardRounded',
            nome: 'Dados Pessoais',
            link: 'dados-pessoais',
          },
          {
            privilegio: 'Programa',
            icon: 'dashboardRounded',
            nome: 'Programa',
            link: 'programa',
          },
        ]
      }];

      for (let index = 0; index < estrutura.length; index++) {

        let filhos = menus.areas.filter(area => area.idParent === estrutura[index].id);
        let secoes = menus.secoes.filter(secao => secao.idArea === estrutura[index].id);

        modulos.push({
          nome: estrutura[index].nome!,
          link: "/" + removerAcentosEspacos(estrutura[index].nome!.toLowerCase()) + "/" + estrutura[index].id,
          privilegio: estrutura[index].nome!,
          icon: "groupsRounded",
          filhos: filhos.map((filho) => {
            return {
              nome: filho.nome!,
              link: removerAcentosEspacos(filho.nome!.toLowerCase()) + "/" + filho.id,
              privilegio: filho.nome!,
              icon: "groupsRounded",
              filhos: menus.secoes.filter((f) => {
                return f.idArea == filho.id
              }).map((secao) => {
                return {
                  nome: secao.nome!,
                  link: removerAcentosEspacos(secao.nome!.toLowerCase()) + "/" + secao.id,
                  privilegio: secao.nome!,
                  icon: "groupsRounded",
                  filhos: menus.conteudos.filter((f) => {
                    return f.idAreas?.some(f => f.idParent == estrutura[index].id) &&
                      f.idSecaos?.some(f => f.idArea == filho.id)
                  }).map((conteudo) => {
                    return {
                      nome: conteudo.titulo!,
                      link: removerAcentosEspacos(conteudo.titulo!.toLowerCase()) + "/" + conteudo.id,
                      privilegio: conteudo.titulo!,
                      icon: "groupsRounded",
                    }
                  })
                }
              })

              //Pegar a Seção antes de pegar o conteudo 
              //Verficar se tem filhos e ai pega a seção, se tiver filhos pegar ...
            }
          })
        });

      }

      modulos.push(
        {
          privilegio: 'Sair',
          icon: 'exit_to_appRounded',
          nome: 'Sair',
          link: '/exit',
          filhos: []
        }
      )

      setMenuItens([]);
      setMenuItens(modulos);

      localStorage.setItem('menusDinamicos', JSON.stringify(modulos));
    }).catch((erro) => {
      toast.error(erro);
    });

    const menuOpened = localStorage.getItem('menuOpened');
    if (menuOpened !== null) setOpen(menuOpened === 'true');
    else setOpen(true);
  }, [auth]);

  const handleClickDrawer = () => {
    localStorage.setItem('menuOpened', (!open).toString());
    setOpen(!open);
    setMenuItenFilhosAbertos("");
  };

  function removerAcentosEspacos(str: string) {
    str = str.replace(" ", "-").replace(" ", "-").replace(" ", "-").replace(" ", "-");
    // Normaliza para a forma decomposta, separando a letra base do acento
    const strSemAcento = str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

    // Remove todos os espaços
    return strSemAcento.replace(/\s/g, '');
  }

  const setHistory = (link: string) => {
    if (link === '/exit') {
      logout();
      return;
    }
    history.push(link);
    // handleClickDrawer();
    setMenuItenFilhosAbertos("");
  };

  const logout = () => {
    usuarioActions.logout();
    toast.info('Logout realizado com sucesso!');
  };

  const handleClickListItemButton = (menuItem: IModulo, mudarRota: boolean = false) => {
    if (!open)
      handleClickDrawer();

    setPathlink("");
    if (!mudarRota || menuItem.link == "/minha-transicao")
      history.push(menuItem.link);
    else
      setPathlink(menuItem.link);

    if (menuItenFilhosAbertos.indexOf(menuItem.privilegio) >= 0) {
      var list = menuItenFilhosAbertos.split(",");
      setMenuItenFilhosAbertos(
        list.filter((f) => f !== menuItem.privilegio).toString()
      );
    } else {
      setMenuItenFilhosAbertos(
        "," + menuItem.privilegio
      );
    }
    if (menuItem.filhos!.length > 0 && menuItenFilhosAbertos.split(",").length > 0) {
      setOpenSubMenu(true);
    }

  };

  return (
    <Grid container>
      <Header
        open={open}
        handleClickDrawer={handleClickDrawer}
        titulo={
          menuItens.find((menuItem) => path === menuItem.link)?.nome ?? ''
        }
      />

      <Drawer variant='permanent' open={open}>
        <DivStato>
          <DrawerHeader>
            <div />
            {open && (
              <LogoGridItem
                item
                xs={12}
                md={6}
                style={{ height: '130px', marginTop: '40px' }}
              >
                {' '}
                <img src={Logo} alt='logo' width={'190px'} />
              </LogoGridItem>
            )}

            <IconButton
              sx={{ color: 'grey' }}
              onClick={handleClickDrawer}
              hidden={true}
            >
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>

          <List sx={{ marginTop: '20px', padding: '10px' }}>
            {menuItens.map((menuItem, index) => (
              <ListItemStato
                key={menuItem.nome}
                disablePadding
                ativo={(pathlink != "" ? pathlink : path) === menuItem.link ? 'true' : 'false'}
              >
                <ListItemButton
                  onClick={() =>
                    !menuItem.filhos || menuItem.filhos.length === 0
                      ? setHistory(menuItem.link as string)
                      : handleClickListItemButton(menuItem, true)
                  }
                  sx={{
                    justifyContent: open ? 'initial' : 'center',
                    padding: '5px'
                  }}
                >
                  <ListItemIconStato
                    open={open}
                    ativo={(pathlink != "" ? pathlink : path) === menuItem.link ? 'true' : 'false'}
                  >
                    <Icon style={{ fontSize: 25 }}>{menuItem.icon}</Icon>
                  </ListItemIconStato>
                  <MenuItemStato
                    primary={menuItem.nome}
                    open={open}
                    ativo={(pathlink != "" ? pathlink : path) === menuItem.link ? 'true' : 'false'}
                  />
                  {
                    menuItem.filhos !== undefined &&
                    menuItem.filhos!.length > 0 &&
                    openSubMenu && open &&
                    (
                      menuItenFilhosAbertos.indexOf(menuItem.privilegio) >= 0 ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    )
                  }
                </ListItemButton>
                <CollapseStato
                  in={menuItenFilhosAbertos.indexOf(menuItem.privilegio) >= 0}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {
                      menuItem.filhos !== undefined &&
                      menuItem.filhos!.length > 0 &&
                      (
                        menuItem.filhos!.map((menuItemFilho: any, index: number) => (
                          <>
                            <ListItemButton
                              key={index}
                              sx={{ pl: 4 }}
                              onClick={() =>
                                setHistory(
                                  menuItem.link
                                    ? `${menuItem.link}/${menuItemFilho.link}`
                                    : `${menuItemFilho.link}`
                                )
                              }
                            >
                              <ListItemIcon sx={{ 'color': 'white' }}>
                                <Icon style={{ fontSize: 30 }}>
                                  {menuItemFilho.icon}
                                </Icon>
                              </ListItemIcon>
                              <MenuItemStato
                                open={openSubMenu}
                                primary={menuItemFilho.nome}
                                ativo={path === menuItem.link ? 'true' : 'false'}
                              />
                              {
                                menuItemFilho.filhos !== undefined &&
                                menuItemFilho.filhos!.length > 0 &&
                                openSubMenu &&
                                (
                                  menuItenFilhosAbertos.indexOf(menuItemFilho.privilegio) >= 0 ? (
                                    open ?? <ExpandLess />
                                  ) : (
                                    open ?? <ExpandMore />
                                  )
                                )
                              }
                            </ListItemButton>

                          </>
                        ))
                      )
                    }
                  </List>
                </CollapseStato>
              </ListItemStato>
            ))}
          </List>
        </DivStato>
      </Drawer>

      <BoxMenu open={open} component='main'>
        <GridConteudoPrincipal container>{children}</GridConteudoPrincipal>
      </BoxMenu>
    </Grid>
  );
};

const GridConteudoPrincipal = styled(Grid)`
  overflow-x: hidden;
  width: 100%;
  min-height: 80%;
  height: 100%;
`;

interface IListMenu {
  ativo?: boolean | string;
  open?: boolean;
}

const ListItemStato = styled(ListItem) <IListMenu>`
  display: flex;
  flex-wrap: wrap;
  max-width: 281px;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 0px;
  padding: 0px;
  width: 90%;
  background-color: ${(p) => (p.ativo === 'true' ? '#118BD7 !important' : 'unset')};
`;

const CollapseStato = styled(Collapse)`
  width: 100%;
  background-color: #66c3fd;
`;

const ListItemIconStato = styled(ListItemIcon) <IListMenu>`
  min-width: 0;
  margin-right: ${(p) => (p.open ? 2 : 'auto')};
  justify-content: center;
  color: ${(p) => (p.ativo === 'true' ? '#efefef !important' : '#bbbbbb !important')};
`;

const MenuItemStato = styled(ListItemText) <IListMenu>`
  opacity: ${(p) => (p.open ? 1 : 0)};
  max-width: 172px;
  text-wrap: ${p => p.open ? 'wrap' : 'no'};

  > span {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: ${(p) => (p.ativo === 'true' ? '#efefef !important' : '#a7a7a7 !important')};
  }
`;

export const GridItem = styled(Grid)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  background-color: #ffffff;
  @media (min-width: 900px) {
    background-color: ${(props: any) => props.color};
  }
`;

export const LogoGridItem = styled(GridItem)`
  background: transparent;
`;

const DivStato = styled.div`
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #f3f3f3;
`;

export default withRouter(MenuBar);
