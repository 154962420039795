import { useBaseActions } from '../base.actions';
import { programasAtom } from './ProgramaState';

function useProgramasActions() {
  const baseActions = useBaseActions('programas', programasAtom, true);
 
  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useProgramasActions };
